import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import PrivacyPolicy from "./page/PrivacyPolicy";
import Footer from "./components/Footer";
import TermsOfUse from "./page/TermsOfUse";
import AboutUs from "./page/AboutUs";
import ScrollToTop from "./components/ScrollToTop";
import History from "./page/History";
import HistoryData from "./page/HistoryData";
import Disclaimer from "./page/Disclaimer";
import CookieConsent from "./components/CookieConsent";
import Cookies from "js-cookie";
import { configureFirebase } from "./firebase";

function Layout() {
  const { isAccepted } = useSelector((state) => state.cookie);
  const a = localStorage.getItem("cookies-popup");
  const localValue = JSON.parse(a);
  useEffect(() => {
    if (!localValue) {
      localStorage.setItem("cookies-popup", JSON.stringify(false));
    }
  }, []);

  useEffect(() => {
    if (isAccepted) {
      Cookies.set("grow-cookies", true, { expires: 365 });
    } else if (!localValue) {
      Cookies.set("grow-cookies", false, { expires: 365 });
    }
  }, []);

  useEffect(() => {
    configureFirebase();
  }, [isAccepted]);
  return (
    <>
      <main>
        <ScrollToTop />
        <Outlet />
        <CookieConsent />
      </main>
      <Footer />
    </>
  );
}
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/history",
        element: <History />,
      },
      {
        path: "/history/:id",
        element: <HistoryData />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-of-use",
        element: <TermsOfUse />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/disclaimer",
        element: <Disclaimer />,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
