import React from "react";
import Container from "./Container";
import jsPDF from "jspdf";
import DownloadLogo from "../assets/images/download.svg";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import { FormatIndianNumber } from "../utiles/FormatIndianNumber";
import autoTable from "jspdf-autotable";
import Watermark from "../assets/images/Watermark.png";
import Logo from "../assets/images/Logo.png";
import TableWithStickyHeader from "./TableWithStickyHeader";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import Cookies from "js-cookie";
import { parseCookie } from "../utiles/parseCookie";

const Table = ({ activeTab, historyData }) => {
  const { investmentDataSIP, noOfInvestementYear, filterInvestmentData } =
  useSelector((state) => (activeTab === 0 ? state.sip : state.lum));
    const cookieAccept = parseCookie('grow-cookies');

  const generatePDF = () => {
    const pdf = new jsPDF();
    const table = document.getElementById("table-to-download");

    const tableData = [];
    const rows = table.querySelectorAll("tr");
    rows.forEach((row, index) => {
      if (index === 0) return; // Skip the header row
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent);
      });
      tableData.push(rowData);
    });

    // Add Logo on first page
    const imgPropsLogo = pdf.getImageProperties(Logo);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const imgWidthLogo = pdfWidth * 0.07;
    const imgHeightLogo =
      imgPropsLogo.height * (imgWidthLogo / imgPropsLogo.width);
    const xLogo = 10;
    const yLogo = 3;
    pdf.addImage(Logo, "PNG", xLogo, yLogo, imgWidthLogo, imgHeightLogo);

    const reportTitle =
      activeTab === 0 || activeTab === 1
        ? "Overall Investment Report"
        : historyData[0].tabName === "SIP & SWP Calculation"
        ? "SIP & SWP Investment Report"
        : "Lumpsum & SWP Investment Report";

    // Calculate the width of the report title
    const reportTitleWidth =
      (pdf.getStringUnitWidth(reportTitle) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;

    // Calculate the center position for the report title
    const pageWidthHead = pdf.internal.pageSize.getWidth();
    const centerX = (pageWidthHead - reportTitleWidth) / 2;

    // Add the report title centered on the page
    pdf.text(reportTitle, centerX, 10);

    // Use autoTable to add the table to the PDF
    autoTable(pdf, {
      head: [
        activeTab === 0 ||
        (historyData && historyData[0]?.tabName === "SIP & SWP Calculation")
          ? headers
          : headerLum,
      ], // Adjust headers as needed
      body: tableData,
      foot: [],
      margin: { left: 10, right: 10 },
      styles: {
        overflow: "linebreak",
        lineWidth: 0.1,
        lineColor: [221, 225, 229],
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        halign: "left",
        valign: "middle",
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.1,
        lineColor: [221, 225, 229],
      },
      columnStyles: {
        halign: "left",
        valign: "middle",
      },
      didDrawPage: (data) => {
        // Add watermark to each page
        const imgPropsWM = pdf.getImageProperties(Watermark);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidthWM = pdfWidth * 0.5;
        const imgHeightWM = imgPropsWM.height * (imgWidthWM / imgPropsWM.width);
        const xWM = (pdfWidth - imgWidthWM) / 2;
        const yWM = (pdfHeight - imgHeightWM) / 2;
        pdf.addImage(Watermark, "PNG", xWM, yWM, imgWidthWM, imgHeightWM);

        // Correctly access the page number
        pdf.setFont("helvetica", "bold");
        pdf.setFont("normal");
        const pageNumber = data.pageNumber;
        pdf.setFontSize(10);
        pdf.text("Page " + pageNumber, 10, pdf.internal.pageSize.height - 10);

        // Add link in footer
        const linkText = "www.growlifefund.com";
        const linkX =
          pdf.internal.pageSize.getWidth() -
          10 -
          (pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize()) /
            pdf.internal.scaleFactor;
        const linkY = pdf.internal.pageSize.getHeight() - 10;
        pdf.text(linkText, linkX, linkY);

        const underlineHeight = 0.5; // Adjust the height of the underline as needed
        pdf.setLineWidth(underlineHeight); // Set the thickness of the underline
        // pdf.setDrawColor('red');
        pdf.line(
          linkX,
          linkY + 2,
          linkX +
            (pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize()) /
              pdf.internal.scaleFactor,
          linkY + 2
        ); // Draw the underline
        pdf.link(
          linkX,
          linkY - 2,
          (pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize()) /
            pdf.internal.scaleFactor,
          4,
          { url: "https://growlifefund.com/" }
        );
      },
    });

    // Dynamically calculate the position for the disclaimer
    const disclaimerYPosition = pdf.autoTable.previous.finalY + 20; // Add some space after the table

    const disclaimerBeforeNewline = "Disclaimer:";
    const disclaimerAfterNewline =
      "Grow Life Fund's calculator is for informational purposes only and does not provide financial advice. It assists users in making approximate calculations for SIP and SWP investments. The calculated values are estimates and may not reflect actual returns. The calculator does not guarantee specific investment results or outcomes. Users should consult a qualified financial advisor before making investment decisions. The website/app owner and operators are not liable for any losses or damages resulting from reliance on the calculated values.";

    // Check if the disclaimer fits on the current page
    const pageWidth = pdf.internal.pageSize.getWidth() + 90; // Adjust the width as needed
    const lines = pdf.splitTextToSize(disclaimerAfterNewline, pageWidth);
    const totalHeight = lines.length * 5 + 8; // Adjust the spacing between lines as needed

    if (disclaimerYPosition + totalHeight > pdf.internal.pageSize.getHeight()) {
      // If the disclaimer doesn't fit, add a new page and set the Y position to the top
      pdf.addPage();
      pdf.setFont("helvetica", "bold");
      pdf.text(disclaimerBeforeNewline, 10, 10); // Set Y position to 10 to start at the top

      // Add footer content to the new page
      pdf.setFont("normal");
      const pageNumber = pdf.internal.getNumberOfPages();
      pdf.setFontSize(10);
      pdf.text("Page " + pageNumber, 10, pdf.internal.pageSize.height - 10);

      const linkText = "www.growlifefund.com";
      const linkX =
        pdf.internal.pageSize.getWidth() -
        10 -
        (pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
      const linkY = pdf.internal.pageSize.getHeight() - 10;
      pdf.text(linkText, linkX, linkY);

      const underlineHeight = 0.5; // Adjust the height of the underline as needed
      pdf.setLineWidth(underlineHeight); // Set the thickness of the underline
      pdf.line(
        linkX,
        linkY + 2,
        linkX +
          (pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize()) /
            pdf.internal.scaleFactor,
        linkY + 2
      ); // Draw the underline
      pdf.link(
        linkX,
        linkY - 2,
        (pdf.getStringUnitWidth(linkText) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor,
        4,
        { url: "https://growlifefund.com/" }
      );
    } else {
      // Set font style to bold for the first part
      pdf.setFont("helvetica", "bold");
      pdf.text(disclaimerBeforeNewline, 10, disclaimerYPosition);
    }

    // Set font style to normal and font size to smaller for the second part
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10); // Adjust the font size as needed

    // Calculate the Y position for each line
    let yPos = disclaimerYPosition + 8; // Adjust the initial Y position as needed
    if (disclaimerYPosition + totalHeight > pdf.internal.pageSize.getHeight()) {
      yPos = 10 + 8; // Adjust the initial Y position to 10 for the new page
    }
    lines.forEach((line) => {
      pdf.text(line, 10, yPos);
      yPos += 5; // Adjust the spacing between lines as needed
    });

    var today = new Date();

    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    var yyyy = today.getFullYear();

    var formattedDate = dd + "/" + mm + "/" + yyyy;
    pdf.save(`investment_report_${formattedDate}.pdf`);
  };



  const headers = [
    "Age",
    "Year",
    "Monthly Install.",
    "Lumpsum Amt. Added",
    "Yearly Investment",
    "Yearly Return",
    "SWP",
    "Yearly SWP Return",
    "Monthly SWP Return",
    "LTCG",
  ];

  const headerLum = [
    "Age",
    "Year",
    "Total Investment",
    "Lumpsum Amt. Added",
    "Yearly Investment",
    "Yearly Return",
    "SWP",
    "Yearly SWP Return",
    "Monthly SWP Return",
    "LTCG",
  ];

  // Add more data as needed

  return (
    <Container>
      <div className={`flex justify-between items-center  px-[0px]`} id='tableScroll'>
        <div className="sm:text-[26px] text-[16px] text-black font-lato-semibold-600 text-left">
          {activeTab === 0 || activeTab === 1
            ? "Overall Investment Report"
            : historyData[0]?.tabName === "SIP & SWP Calculation"
            ? "SIP & SWP Investment Report"
            : "Lumpsum & SWP Investment Report"}
        </div>
        <div className="group relative">
          <button onClick={() => {generatePDF();
          cookieAccept && logEvent(analytics,`download_report`)}}>
            <img
              src={DownloadLogo}
              alt="Download-Icon"
              className="sm:w-auto sm:h-auto w-[24px] h-[24px]"
            />
          </button>
          <span className="absolute hidden font-lato-regular-400 group-hover:flex justify-center -left-[65px] -top-2 -translate-y-full w-[100px]  m-auto px-1 py-1 bg-gray rounded-lg text-center text-white text-[12px] after:content-[''] after:absolute after:left-[80%] after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-[transparent] after:border-b-[transparent] after:border-t-gray">
           Download PDF Report
          </span>
        </div>
      </div>
      <div
        className={`overflow-x-auto  mt-[25px] rounded-[8px] border border-[#0752B533] px-0 `}
      >
        <TableWithStickyHeader
          id="table-to-download"
          className="min-w-full border border-[#0752B533]  overflow-hidden border-separate border-spacing-0 custom-table"
        >
          <thead className="bg-[#ffffff] text-left">
            <tr>
              {headers?.map((header, index, array) => (
                <th
                  key={index}
                  scope="col"
                  className={`px-[12px]  sm:text-[16px] text-[14px] py-[14px] text-black font-lato-semibold-600 ${
                    index !== array?.length - 1 ? "border-r" : ""
                  } border-b border-[#0752B533]`}
                >
                  {activeTab === 1 && index === 2
                    ? "Total Investment"
                    : historyData &&
                      historyData[0]?.tabName === "Lumpsum & SWP Calculation" &&
                      index === 2
                    ? "Total Investment"
                    : header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white text-left">
            {investmentDataSIP?.length === 0 ? (
              // Render historyData when investmentDataSIP is empty
              historyData ? (
                historyData?.map((item, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-[#F6F8FB]" : "bg-white"
                    } `}
                  >
                    <td
                      key={index}
                      className={`px-[12px] py-[14px] whitespace-nowrap
                    sm:text-[16px] text-[14px] text-gray border-r
                     border-[#0752B533] ${
                       index === historyData?.length - 1 ? "" : "border-b"
                     }`}
                    >
                      {item?.age}
                    </td>
                    <td
                      className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r  ${
                        index === historyData?.length - 1 ? "" : "border-b"
                      } border-[#0752B533]`}
                    >
                      {item?.year}
                    </td>
                    <td
                      className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r  ${
                        index === historyData?.length - 1 ? "" : "border-b"
                      } border-[#0752B533]`}
                    >
                      {item?.monthlyInvestment === 0 || item.monthlyInvestment ==""
                        ? "-"
                        : FormatIndianNumber(item?.monthlyInvestment)}
                    </td>
                    <td
                      className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r  ${
                        index === historyData?.length - 1 ? "" : "border-b"
                      } border-[#0752B533]`}
                    >
                      {item?.investmentAmounts === 0
                        ? "-"
                        : FormatIndianNumber(item?.investmentAmounts)}
                    </td>
                    <td
                      className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r  ${
                        index === historyData?.length - 1 ? "" : "border-b"
                      } border-[#0752B533]`}
                      >
                      {item?.yearlyInvestment === 0 || item.yearlyInvestment ==""
                        ? "-"
                        : FormatIndianNumber(item?.yearlyInvestment)}
                    </td>
                    <td
                      className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r  ${
                        index === historyData?.length - 1 ? "" : "border-b"
                      } border-[#0752B533]`}
                    >
                      {item?.yearlyReturn === 0 || item.yearlyReturn ==""
                        ? "-"
                        : FormatIndianNumber(item?.yearlyReturn)}
                    </td>
                    <td
                      className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r  ${
                        index === historyData?.length - 1 ? "" : "border-b"
                      } border-[#0752B533]`}
                    >
                      {item?.swpAmount === 0
                        ? "-"
                        : FormatIndianNumber(item?.swpAmount)}
                    </td>
                    <td
                      className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r  ${
                        index === historyData?.length - 1 ? "" : "border-b"
                      } border-[#0752B533]`}
                    >
                      {/* {yearlyWithdraw === " "
                        ? "-"
                        : item?.yearlyReturnAfterSWP == 0
                        ? "-"
                        : FormatIndianNumber(item?.yearlyReturnAfterSWP)} */}
                      {item?.yearlyReturnAfterSWP == 0
                        ? "-"
                        : FormatIndianNumber(item?.yearlyReturnAfterSWP)}
                    </td>
                    <td
                      className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r ${
                        index === historyData?.length - 1 ? "" : "border-b"
                      } border-[#0752B533] text-[14px] text-gray`}
                    >
                      {item?.monthlyReturn === 0
                        ? "-"
                        : FormatIndianNumber(item?.monthlyReturn)}
                    </td>
                    <td
                      className={`px-[12px] py-[14px] sm:text-[16px] ${
                        index === historyData?.length - 1 ? "" : "border-b"
                      } border-[#0752B533] text-[14px] text-gray`}
                    >
                      {item?.ltcgAmount == 0 
                        ? "-"
                        : FormatIndianNumber(item?.ltcgAmount )}
                    </td>
                  </tr>
                ))
              ) : (
                // Render a single row with "No data available" message if historyData is empty
                <tr>
                  <td
                    colSpan="9"
                    className={`px-[12px] py-[14px] whitespace-nowrap sm:text-[20px] text-center text-[14px] text-black font-lato-regular-400`}
                  >
                    No data available
                  </td>
                </tr>
              )
            ) : noOfInvestementYear === "" ? (
              <tr>
                <td
                  colSpan="9"
                  className={`px-[12px] py-[14px] whitespace-nowrap sm:text-[20px] text-center text-[14px] text-black font-lato-regular-400`}
                >
                  No data available
                </td>
              </tr>
            ) : (
              (
                (filterInvestmentData?.length > 0 && filterInvestmentData) ||
                investmentDataSIP
              ).map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-[#F6F8FB]" : "bg-white"
                  } `}
                >
                  <td
                    key={index}
                    className={`px-[12px] py-[14px] whitespace-nowrap
                    sm:text-[16px] text-[14px] text-gray border-r
                     border-[#0752B533] ${
                       index === filterInvestmentData?.length - 1 || index === investmentDataSIP?.length - 1
                         ? ""
                         : "border-b"
                     } `}
                  >
                    {item?.age}
                  </td>
                  <td
                    className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r border-[#0752B533] ${
                      index === filterInvestmentData?.length - 1 || index === investmentDataSIP?.length - 1
                        ? ""
                        : "border-b"
                    }`}
                  >
                    {item?.year}
                  </td>
                  <td
                    className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r border-[#0752B533] ${
                      index === filterInvestmentData?.length - 1 || index === investmentDataSIP?.length - 1
                        ? ""
                        : "border-b"
                    }`}
                  >
                    {item?.monthlyInvestment === 0 ||
                    item?.monthlyInvestment === ""
                      ? "-"
                      : FormatIndianNumber(item?.monthlyInvestment)}
                  </td>
                  <td
                    className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r border-[#0752B533] ${
                      index === filterInvestmentData?.length - 1 || index === investmentDataSIP?.length - 1
                        ? ""
                        : "border-b"
                    }`}
                  >
                    {item?.investmentAmounts === 0
                      ? "-"
                      : FormatIndianNumber(item?.investmentAmounts)}
                  </td>
                  <td
                    className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r border-[#0752B533] ${
                      index === filterInvestmentData?.length - 1 || index === investmentDataSIP?.length - 1
                        ? ""
                        : "border-b"
                    }`}
                  >
                    {item?.yearlyInvestment === 0 ||
                    item?.yearlyInvestment === ""
                      ? "-"
                      : FormatIndianNumber(item?.yearlyInvestment)}
                  </td>
                  <td
                    className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r border-[#0752B533] ${
                      index === filterInvestmentData?.length - 1 || index === investmentDataSIP?.length - 1
                        ? ""
                        : "border-b"
                    }`}
                  >
                    {item?.yearlyReturn === 0
                      ? "-"
                      : FormatIndianNumber(item?.yearlyReturn)}
                  </td>
                  <td
                    className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r border-[#0752B533] ${
                      index === filterInvestmentData?.length - 1  || index === investmentDataSIP?.length - 1
                        ? ""
                        : "border-b"
                    }`}
                  >
                    {item?.swpAmount === 0
                      ? "-"
                      : FormatIndianNumber(item?.swpAmount)}
                  </td>
                  <td
                    className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r border-[#0752B533] ${
                      index === filterInvestmentData?.length - 1 || index === investmentDataSIP?.length - 1
                        ? ""
                        : "border-b"
                    }`}
                  >
                    {/* {yearlyWithdraw == ""
                      ? "- "
                      : item?.yearlyReturnAfterSWP == 0
                      ? "-"
                      : FormatIndianNumber(item?.yearlyReturnAfterSWP)} */}
                    {item?.yearlyReturnAfterSWP == 0
                      ? "-"
                      : FormatIndianNumber(item?.yearlyReturnAfterSWP)}
                  </td>
                  <td
                    className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray border-r border-[#0752B533] ${
                      index === filterInvestmentData?.length - 1 || index === investmentDataSIP?.length - 1
                        ? ""
                        : "border-b"
                    } `}
                  >
                    {item?.monthlyReturn === 0
                      ? "-"
                      : FormatIndianNumber(item?.monthlyReturn)}
                  </td>
                  <td
                      className={`px-[12px] py-[14px] sm:text-[16px] ${
                        index === filterInvestmentData?.length - 1 || index === investmentDataSIP?.length - 1
                          ? ""
                          : "border-b"
                      } border-[#0752B533] text-[14px] text-gray`}
                    >
                      {item?.ltcgAmount === 0 || item?.ltcgAmount == ''
                        ? "-"
                        : FormatIndianNumber(item?.ltcgAmount )}
                    </td>
                </tr>
              ))
            )}
          </tbody>
        </TableWithStickyHeader>
      </div>
    </Container>
  );
};

export default Table;
