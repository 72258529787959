export const Button = ({ children, onClick, type, className, disabled }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${
        disabled
          ? "cursor-not-allowed hover:from-[#07B5B5] hover:to-[#058282] "
          : ""
      } text-center min-w-[160px]  rounded-full from-[#07B5B5] to-[#058282] hover:from-[#07CCCC] hover:to-[#069C9C] transition-all  bg-gradient-to-b ${className} sm:h-[48px] h-[40px] focus:outline-none`}
    >
      <span className="flex items-center justify-center sm:px-4 px-3 py-[7px] rounded-full  sm:text-[18px] text-[16px] font-lato-semibold-600 text-white ">
        {children}
      </span>
    </button>
  );
};
