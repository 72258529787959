import { combineReducers, configureStore } from '@reduxjs/toolkit';
import sipAndSwpSlice from '../redux/calculations/slice'
import lumpsumSlice from '../redux/calculations/lumpsumSlice'
import cookieConsentSlice from '../redux/calculations/cookieConsentSlice'

const reducers = combineReducers({
  sip: sipAndSwpSlice,
  lum: lumpsumSlice,
  cookie: cookieConsentSlice
})

const store = configureStore({
  reducer:  reducers,
});


export default store;