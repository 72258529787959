import React from "react";
import Container from "../components/Container";
import HeaderBanner from "../components/HeaderBanner";
const Disclaimer = () => {
  return (
    <Container>
      <div className="my-[24px] ">
        <HeaderBanner name="Disclaimer" backUrl='/'/>
        <div>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            The information provided by Grow Life Fund ("we," "us," or "our") on {" "}
            <a
              href="https://growlifefund.com/"
              target="_blank"
              className="underline text-[#0000FF]"
            >
             www.growlifefund.com
            </a>{" "}
            and our mobile application is for general informational purposes
            only. All information on the Site and our mobile application is
            provided in good faith, however, we make no representation or
            warranty of any kind, express or implied, regarding the accuracy,
            adequacy, validity, reliability, availability, or completeness of
            any information on the Site or our mobile application.
          </p>
          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            Investment Disclaimer
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            The Site and our mobile application offer financial information
            related to Systematic Investment Plans (SIPs), Systematic Withdrawal
            Plans (SWP), and other investment strategies. This information is
            intended for informational and educational purposes only and should
            not be construed as financial advice. The use of any information
            provided on the Site or our mobile application is solely at your own
            risk.
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 mt-[20px]">
            Before making any investment decision, we recommend consulting with
            a qualified financial advisor to ensure that any investment
            strategies or investments reflect your individual financial
            situation and goals.
          </p>
          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            No Guarantees
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            While we aim to provide accurate and up-to-date information, the
            investment market is dynamic, and the information provided on the
            Site or our mobile application may not reflect the most current
            market conditions. We do not guarantee the timeliness, accuracy, or
            completeness of the information provided and will not be liable for
            any errors or omissions in the information or for any losses,
            injuries, or damages incurred from its display or use.
          </p>
          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            External Links Disclaimer
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            The Site and our mobile application may contain (or you may be sent
            through the Site or our mobile application) links to other websites
            or content belonging to or originating from third parties or links
            to websites and features in banners or other advertising. Such
            external links are not investigated, monitored, or checked for
            accuracy, adequacy, validity, reliability, availability, or
            completeness by us.
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 mt-[20px]">
            We do not warrant, endorse, guarantee, or assume responsibility for
            the accuracy or reliability of any information offered by
            third-party websites linked through the Site or any website or
            feature linked in any banner or other advertising. We will not be a
            party to or in any way be responsible for monitoring any transaction
            between you and third-party providers of products or services.
          </p>

          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            Professional Disclaimer
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            The Site cannot and does not contain financial advice. The financial
            information is provided for general informational and educational
            purposes only and is not a substitute for professional advice.
            Accordingly, before taking any actions based upon such information,
            we encourage you to consult with the appropriate professionals. We
            do not provide any kind of financial advice.
          </p>
          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            Contact Us
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            If you have any questions about this disclaimer, please contact us
            at: contact(at)growlifefund(dot)com.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Disclaimer;