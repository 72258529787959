import React, { useState } from "react";
import { Button } from "./Button";
import { useDispatch } from "react-redux";
import { setIsAccepted } from "../redux/calculations/cookieConsentSlice";
import Cookies from "js-cookie";

const CookieConsent = ({}) => {
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  // const cookies = localStorage.getItem("grow-cookies");
  const dispatch = useDispatch();
  const cookies = Cookies.get("grow-cookies");
  const a = localStorage.getItem("cookies-popup");
  const cookieVar = JSON.parse(a);

  const handleAcceptAllCookies = () => {
    Cookies.set("grow-cookies", true, { expires: 365 });
    setShowCookieConsent(true);
    dispatch(setIsAccepted(true));
    localStorage.setItem("cookies-popup", JSON.stringify(true));
  };

  const handleRejectAllCookies = () => {
    Cookies.set("grow-cookies", false, { expires: 365 });
    setShowCookieConsent(true);
    dispatch(setIsAccepted(false));
    localStorage.setItem("cookies-popup", JSON.stringify(true));
  };
  return (
    <>
      {!cookieVar && (
        <div className="fixed  drop-shadow-[0px_0px_40px_rgba(152,167,167,0.15)] min-h-[105px]  bottom-0 left-0 w-full bg-white text-white sm:py-[40px] py-[20px] px-4 text-center   ">
          <div className="max-w-[1340px]  sm:px-[40px] px-0 w-full m-auto md:flex block justify-between">
            <div className="">
              <div className="md:text-[28px] sm:text-[24px] text-[22px] text-left text-black font-lato-semibold-600  mb-2">
                About Cookies
              </div>
              <div className="md:max-w-[880px] w-full text-left text-gray font-lato-regular-400 md:text-[20px] sm:text-[18px] text-[16px]">
                This website uses cookies in order to offer you the most
                relevant information. Please accept cookies for optimal
                performance.
              </div>
            </div>

            <div className="gap-2 md:block sm:flex  md:mt-0 mt-[20px] justify-between">
              <Button
                onClick={handleAcceptAllCookies}
                className="sm:max-w-[272px] w-full"
              >
                Yes, Accept All
              </Button>
              <button
                className="sm:max-w-[272px] sm:h-[48px] h-[40px] md:mt-[20px] sm:mt-0 mt-[20px] w-full border sm:text-[18px] border-gray rounded-[178px]  text-gray font-lato-semibold-600 sm:px-4 px-3 py-[7px] "
                onClick={handleRejectAllCookies}
              >
                Reject All
              </button>
            </div>
          </div>{" "}
        </div>
      )}
    </>
  );
};

export default CookieConsent;
