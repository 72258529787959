import React, { useState, useEffect } from "react";
import Container from "./Container";
import Tab from "./Tab";
import SipAndSwp from "./SipAndSwp";
import Lumpsum from "./Lumpsum";
import { FreeAmountModal } from "./FreeAmountModal";
import ChartComponent from "./ChartComponent";
import { Button } from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { setInvestmentDataSIP, setIsOpen, setRemoveFields } from "../redux/calculations/slice";
import {
  setInvestmentDataLUM,
  setIsOpenLum,
  setRemoveLumpsumFields,
} from "../redux/calculations/lumpsumSlice";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { parseCookie } from '../utiles/parseCookie';

const SIPBoard = ({ setShowTable, activeTab, setActiveTab }) => {
  const dispatch = useDispatch();
  const cookieAccept = parseCookie('grow-cookies');
  const [calcDone, setCalcDone] = useState(false);
const location = useLocation()
  const {
    age,
    monthlyInvestment,
    incrementPerYear,
    approxReturn,
    noOfInvestementYear,
    swpStartYear,
    yearlyWithdraw,
    yearlyIncrementInWithdrawSwp,
    isOpen,
    investmentAmounts,
    sipErrors,
    selectedOption
  } = useSelector((state) => (activeTab === 0 ? state.sip : state.lum));

  useEffect(() => {
    setCalcDone(false);
  }, [
    age,
    monthlyInvestment,
    incrementPerYear,
    approxReturn,
    noOfInvestementYear,
    swpStartYear,
    yearlyWithdraw,
    yearlyIncrementInWithdrawSwp,
    investmentAmounts,
  ]);
  const resetValues = () => {
    dispatch(setRemoveFields());
    dispatch(setRemoveLumpsumFields());
  };
  const generateData = () => {
    const data = [];
    let totalInvestment = 0;
    let currentMonthlyInvestment = monthlyInvestment?.replace(/,/g, "");
    let yearlyWithdrawAmt = yearlyWithdraw?.replace(/,/g, "");
    let ageCount = age;
    let swpStartYearCount = swpStartYear;
    let previousYearReturn = 0;

    let yearlyReturnAfterSWP = 0;
    for (let year = 1; year <= 45; year++) {
      const investmentAmount = parseFloat(investmentAmounts[year - 1]) || 0;

      //YEARLY RETURN INVESTMENT
      const yearlyInvestment =
        activeTab === 0
          ? currentMonthlyInvestment * 12 + investmentAmount
          : Number(currentMonthlyInvestment) + investmentAmount;
      totalInvestment += yearlyInvestment;

      // YEARLY RETURN CALCULATION
      let yearlyReturn = 0;

      if (year === 1) {
        yearlyReturn =
          yearlyInvestment * (approxReturn / 100) + yearlyInvestment;
        previousYearReturn = yearlyReturn; // Initialize previousYearReturn for the first year
      } else {
        yearlyReturn =
          (previousYearReturn +
            (year <= noOfInvestementYear ? yearlyInvestment : 0)) *
            (approxReturn / 100) +
          (year <= noOfInvestementYear ? yearlyInvestment : 0) +
          previousYearReturn;
        previousYearReturn = yearlyReturn; // Update previousYearReturn for subsequent years
      }
      // SWP CALCULATION
      let swpAmount = 0;
      if (year >= swpStartYear) {
        swpAmount =
          yearlyWithdrawAmt *
          Math.pow(1 + yearlyIncrementInWithdrawSwp / 100, year - swpStartYear);
      }
      // YEARLY RETURN AFTER SWP CALCULATION
      if (year === 1) {
        yearlyReturnAfterSWP =
          yearlyInvestment * (approxReturn / 100) + yearlyInvestment;
      } else {
        yearlyReturnAfterSWP =
          ((year <= noOfInvestementYear ? yearlyInvestment : 0) +
            yearlyReturnAfterSWP -
            swpAmount) *
            (approxReturn / 100) +
          (yearlyReturnAfterSWP +
            (year <= noOfInvestementYear ? yearlyInvestment : 0) -
            swpAmount);
      }

      let ltcgAmount = 0
      //LTCG
      if(swpStartYearCount == 1){
        // let a= yearlyWithdraw 
        ltcgAmount= (swpAmount * 15 / 100)
      }
      else{
        // let a= (yearlyWithdraw)
        ltcgAmount= (swpAmount * 10 / 100)

      }
      // MONTHLY RETURN CALCULATION
      let monthlyReturn = swpAmount / 12;
      let tabName =
        activeTab === 0 ? "SIP & SWP Calculation" : "Lumpsum & SWP Calculation";
      data.push({
        tabName: tabName,
        year,
        investmentAmounts: investmentAmount,
        age: ageCount++,
        swpStartYear: swpStartYearCount <= year ? swpStartYearCount++ : year,
        monthlyInvestment:
          year <= noOfInvestementYear
            ? Math.round(currentMonthlyInvestment)
            : "",
        yearlyInvestment:
          year <= noOfInvestementYear ? Math.round(yearlyInvestment) : "",
        totalInvestment:
          year <= noOfInvestementYear ? Math.round(totalInvestment) : "",
        yearlyReturn: Math.round(yearlyReturn),
        swpAmount: swpAmount,
        monthlyReturn: Math.round(monthlyReturn),
        yearlyReturnAfterSWP:
          yearlyWithdraw === "" ? "" : Math.round(yearlyReturnAfterSWP),
          ltcgAmount: year >= swpStartYear ?ltcgAmount:''
      });

      // Update monthly investment for next year if still within investment years
      if (year < noOfInvestementYear) {
        currentMonthlyInvestment *= 1 + incrementPerYear / 100;
      }
    }

    dispatch(
      activeTab === 0 ? setInvestmentDataSIP(data) : setInvestmentDataLUM(data)
    );

    setCalcDone(true);

    return data;
  };

  useEffect(() => {
    !sipErrors && setShowTable(false);
  }, [sipErrors]);

  const tabs = [
    {
      title: "SIP & SWP",
      content: (
          <SipAndSwp
            setShowTable={setShowTable}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            generateData={generateData}
            resetAllValues={resetValues}
          />
      ),
    },
    {
      title: "Lumpsum",
      content: (
          <Lumpsum
            setShowTable={setShowTable}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            generateData={generateData}
            resetAllValues={resetValues}
          />
      ),
    },
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  function graphDescription(){

    function investmentDuration(){

      function splitString(){
        let [_, lastYear] = selectedOption?.split('-');

        return lastYear === '1' ? '1 year' : `${lastYear} years`;
      }

      switch(selectedOption){
        case 'All' : return '45 years';
        case null : return '0 year';
        default : return splitString();
      }
    }

    return `The above values are calculated for ${investmentDuration()} terms`;
  }

  const closeModal = () => {
    dispatch(activeTab === 0 ? setIsOpen(false) : setIsOpenLum(false));
  };




  
  return (
    <>
      <Container>
        <div className="mt-[40px] ">
          <div className="sm:drop-shadow-[0px_0px_15px_rgba(152,167,167,0.15)]  bg-white mb-[20px] rounded-[24px] w-full lg:flex block  md:p-[40px] sm:p-[20px] p-0 gap-[48px]">
            <div className="lg:w-3/5 w-full flex flex-col">
              <Tab
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setShowTable={setShowTable}
                resetAllValues={resetValues}
              />
              <div className="flex justify-between items-center"></div>
            </div>
            <div className="lg:w-2/5 w-full bg-[#F6F8FB] rounded-[20px] md:mt-0 sm:p-[40px] p-[10px]">
              <ChartComponent
                activeTab={activeTab}
                setShowTable={setShowTable}
                calcDone={calcDone}
              />
              <div className="w-full sm:mt-[30px] mt-[30px]">
              <div className="text-gray-font sm:text-[14px] text-[12px] font-lato-regular-400">
                {graphDescription()}
              </div>
              </div>
              <div className="sm:mt-[25px] mt-[25px]">
                <Button
                  disabled={
                    !sipErrors ||
                    monthlyInvestment === "0" ||
                    noOfInvestementYear === "" ||
                    !calcDone
                      ? true
                      : false
                  }
                  className="w-full"
                  onClick={async () => {
                  cookieAccept &&  logEvent(analytics,`report_details`)
                    setShowTable(true); // Set showTable state to true
                    setTimeout(() => {
                      const faqsForm = document.getElementById("tableScroll"); // Get element with ID 'tableScroll'
                      if (faqsForm) {
                        const scrollMargin = 200; // Adjust this value as needed
                        const scrollPosition =
                          faqsForm.getBoundingClientRect().top +
                          window.pageYOffset -
                          scrollMargin;
                        window.scrollTo({
                          top: scrollPosition,
                          behavior: "smooth",
                        }); // Scroll element into view smoothly
                      }
                    }, 100);
                  }}
                >
                  Details
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <FreeAmountModal
        onClose={closeModal}
        activeTab={activeTab}
        generateData={generateData}
      />
    </>
  );
};

export default SIPBoard;
