import React from "react";
import BannerImg from "../assets/images/banner-img.png";
import GrowLogo from "../assets/images/grow-logo.svg";
import Container from "./Container";
const HomeBanner = () => {
  return (
    <div className="">
      <div className={`md:min-h-[465px] h-auto  bg-home-banner  bg-no-repeat bg-cover bg-center md:pb-0 pb-[40px] `}>
        {" "}
        <Container>
          <div className="relative z-1 ">
            <div className="flex  pt-[20px] lg:justify-start justify-center">
              <div>
                <img src={GrowLogo} alt="Grow-Logo" className='sm:h-auto  h-[39px]' />
              </div>
            </div>
            <div className="lg:flex block justify-between">
              <div className=" lg:max-w-[332px] sm:max-w-[348px] max-w-[245px] lg:m-0 m-auto font-lato-bold-700 w-full lg:text-left   text-center lg:text-[38px] sm:text-[28px] text-[20px] text-[#282828] lg:mt-[40px] mt-[20px]">
                Optimize Investments with Our{" "}
                <span className="bg-gradient-primary bg-clip-text text-[transparent]"> SIP & SWP</span> Calculator
              </div>
              <div className="lg:mt-0 mt-[20px]">
                <img src={BannerImg} alt="Banner-Img" className='lg:h-auto  sm:h-[250px]  h-[172px] lg:w-[450px] w-auto m-auto' />
              </div>
            </div>
          </div>
        </Container> 
      </div>
      <div className="md:mt-[36px] mt-[20px] md:px-[40px] px-[20px] ">
            <div className="lg:text-[38px] sm:text-[28px] text-[20px] m-auto md:max-w-full max-w-[260px]   text-black  font-lato-bold-700">Calculate your <span className="bg-gradient-primary bg-clip-text text-[transparent]">SIP & SWP </span><span className="bg-gradient-primary bg-clip-text text-[transparent]">Investment</span> Returns</div>
            <div className="sm:pt-0 pt-[12px] sm:max-w-[1054px] max-w-[335px] w-full m-auto sm:text-[18px] text-[14px] text-gray-font  font-lato-regular-400">Explore the Potential Growth of Your SIP & SWP Investments with our Calculator. Gain insights into compounding returns and determine optimal investment amounts tailored to your financial objectives.</div>
          </div>
    </div>
  );
};

export default HomeBanner;
