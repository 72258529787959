import React from 'react'
import { Link } from 'react-router-dom'
import GrowLogo from "../assets/images/grow-logo.svg";
import BackArrow from "../assets/images/back-arrow.svg";

const HeaderBanner = ({name,backUrl}) => {
  return (
    <div>
        <div className="flex  justify-center  sm:border-b border-gray-light pb-0 sm:pb-[28px] ">
          <Link to="/">
            {" "}
            <img
              src={GrowLogo}
              alt="Grow-Logo"
              className="md:h-auto h-[44px] "
            />
          </Link>
        </div>
        <div className=" flex justify-between w-full sm:py-[40px] py-[20px]">
          <button className="flex items-center  bg-gradient-to-r from-[#07B5B5] to-[#058282] bg-clip-text text-[transparent] font-lato-bold-700 sm:text-[22px] text-[16px]">
            <Link to={backUrl} className="flex items-center">
              {" "}
              <img src={BackArrow} alt="back-arrow" /> Back
            </Link>
          </button>

          <div className="font-lato-semibold-600 sm:ml-[-100px] ml-[-70px] text-black sm:text-[38px] text-[20px] text-center ">
          {name}
          </div>
          <div className=""></div>
        </div>
    </div>
  )
}

export default HeaderBanner