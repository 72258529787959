import React, { useEffect, useMemo, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { FormatIndianNumber } from "../utiles/FormatIndianNumber";
import { useDispatch, useSelector } from "react-redux";
import {
  setInvestmentDataSIP,
  setFilterInvestmentData,
  setSelectedOption,
} from "../redux/calculations/slice";
import {
  setSelectedOptionLum,
  setFilterInvestmentDataLum,
  setInvestmentDataLUM,
} from "../redux/calculations/lumpsumSlice";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import { parseCookie } from "../utiles/parseCookie";

const ChartComponent = React.memo(({ activeTab, setShowTable, calcDone }) => {
  const dropdownRef = useRef(null);
  const cookieAccept = parseCookie('grow-cookies');
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const {
    investmentDataSIP,
    selectedOption,
    filterInvestmentData,
    noOfInvestementYear,
  } = useSelector((state) => (activeTab === 1 ? state.lum : state.sip));

  useEffect(() => {
    // dispatch(
    //   activeTab === 0
    //     ? setFilterInvestmentData(investmentDataSIP)
    //     : setFilterInvestmentDataLum(investmentDataSIP)
    // );
    if (calcDone) {
      if (selectedOption === "All") {
        dispatch(
          activeTab === 0
            ? setFilterInvestmentData([])
            : setFilterInvestmentDataLum([])
        );
        dispatch(
          activeTab === 0
            ? setInvestmentDataSIP(investmentDataSIP)
            : setInvestmentDataLUM(investmentDataSIP)
        );
        // resetInvestmentData();
      } else if (selectedOption === `0-${noOfInvestementYear}`) {
        const filteredData = investmentDataSIP.filter((item) => item.year <= noOfInvestementYear);
        dispatch(
          activeTab === 0
            ? setFilterInvestmentData(filteredData)
            : setFilterInvestmentDataLum(filteredData)
        );
      } else if (selectedOption === "0-30") {
        const filteredData = investmentDataSIP.filter((item) => item.year <= 30);
        dispatch(
          activeTab === 0
            ? setFilterInvestmentData(filteredData)
            : setFilterInvestmentDataLum(filteredData)
        );
      }
    }
  }, [investmentDataSIP, selectedOption, calcDone]);

  const handleOptionClick = (option) => {
    cookieAccept && logEvent(analytics,`filter_report`)
    dispatch(
      activeTab === 0 ? setSelectedOption(option) : setSelectedOptionLum(option)
    );

    if (option === "All") {
      dispatch(
        activeTab === 0
          ? setFilterInvestmentData([])
          : setFilterInvestmentDataLum([])
      );
      dispatch(
        activeTab === 0
          ? setInvestmentDataSIP(investmentDataSIP)
          : setInvestmentDataLUM(investmentDataSIP)
      );
      // resetInvestmentData();
    } else if (option === `0-${noOfInvestementYear}`) {
      const filteredData = investmentDataSIP.filter((item) => item.year <= noOfInvestementYear);
      dispatch(
        activeTab === 0
          ? setFilterInvestmentData(filteredData)
          : setFilterInvestmentDataLum(filteredData)
      );
    } else if (option === "0-30") {
      const filteredData = investmentDataSIP.filter((item) => item.year <= 30);
      dispatch(
        activeTab === 0
          ? setFilterInvestmentData(filteredData)
          : setFilterInvestmentDataLum(filteredData)
      );
    }
    setShowTable(false);
    setIsOpen(false);
  };



  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (noOfInvestementYear > 0) {
      dispatch(activeTab === 0?setSelectedOption(`All`):setSelectedOptionLum(`All`));
      if (calcDone) {
        const filteredData = investmentDataSIP.filter((item) => item.year <= noOfInvestementYear);
        dispatch(
          activeTab === 0
            ? setFilterInvestmentData(filteredData)
            : setFilterInvestmentDataLum(filteredData)
        );
      }
    }
  }, [noOfInvestementYear]);

  const generateDropdownOptions = (noOfInvestementYear) => {
    const options = [];
    if (noOfInvestementYear != 30) {
      const maxOption = Math.min(noOfInvestementYear, 46); // Maximum option will be either 30 or noOfInvestmentYear, whichever is smaller
      options.push({ label: `0-${maxOption}`, value: `0-${maxOption}` });
    }
    options.push({ label: "0-30", value: "0-30" });
    options.push({ label: "All", value: "All" });
    return options;
  };

  function getLastTotalInvestment(data) {
    for (let i = data?.length - 1; i >= 0; i--) {
      const totalInvestment = data[i]?.totalInvestment;
      if (typeof totalInvestment === "number") {
        return totalInvestment;
      }
    }
    return null; // If no numerical totalInvestment found
  }

  const fData =
    filterInvestmentData?.length > 0 ? filterInvestmentData : investmentDataSIP;

  const totalInvestment = getLastTotalInvestment(fData);

  const yearlyReturnTotal = fData[fData.length - 1]?.yearlyReturn;

  const yearlyReturnAfterSWPTotal =
    fData[fData.length - 1]?.yearlyReturnAfterSWP;

  const totalWithDrawal = fData
    ?.map((item) => item.swpAmount)
    ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const logBase = 10;
  const logScale = (value) => Math.log(value + 1) / Math.log(logBase);

  // Apply logarithmic scaling to each data value
  const scaledData = [
    totalInvestment ? logScale(totalInvestment) : 5,
    yearlyReturnTotal ? logScale(yearlyReturnTotal) : 5,
    totalWithDrawal
      ? logScale(totalWithDrawal)
      : investmentDataSIP?.length && totalWithDrawal === 0
      ? 0
      : 5,
    yearlyReturnAfterSWPTotal
      ? logScale(yearlyReturnAfterSWPTotal)
      : investmentDataSIP?.length && yearlyReturnAfterSWPTotal == 0
      ? 0
      : 5,
  ];

  const data = useMemo(() => {
    return {
      labels: [
        "Total Investment",
        "Est. Return",
        "Total Withdrawal",
        "Est. Return after SWP",
      ],
      datasets: [
        {
          label: [
            FormatIndianNumber(totalInvestment),
            FormatIndianNumber(yearlyReturnTotal),
            FormatIndianNumber(totalWithDrawal),
            FormatIndianNumber(yearlyReturnAfterSWPTotal),
          ],
          // data: [
          //   totalInvestment ? totalInvestment : 5,
          //   yearlyReturnTotal ? yearlyReturnTotal : 5,
          //   totalWithDrawal ? totalWithDrawal : 5,
          //   yearlyReturnAfterSWPTotal ? yearlyReturnAfterSWPTotal : 5,
          //   // yearlyWithdraw == "" ? "0" :yearlyReturnAfterSWPTotal ? yearlyReturnAfterSWPTotal : 5 ,
          // ],
          data: scaledData,
          backgroundColor: ["#57C6F4", "#FFC566", "#FF8566", "#76C576"],
          spacing: 5,
          cutout: "55%",
        },
      ],
    };
  }, [
    totalInvestment,
    yearlyReturnTotal,
    yearlyReturnAfterSWPTotal,
    totalWithDrawal,
    investmentDataSIP,
  ]);
  const options = {
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      tooltip: {
        enabled: true,
        caretSize: 0,
        backgroundColor: "#ffffff",
        boxShadow: "#98A7A726",
        bodyColor: "#282828",
        fontFamily: "Helvetica Neue",
        titleColor: "#282828",
        titleFont: {
          weight: "normal",
          size: 14,
        },
        bodyFont: {
          weight: "normal",
          size: 16,
        },
        align: "center",
        displayColors: false,
        titleAlign: "center",
        bodyAlign: "center",
        padding: {
          x: 20, // Horizontal padding of tooltip
          y: 12, // Vertical padding of tooltip
        },
        callbacks: {
          label: function (context) {
            let label = data?.labels[context.dataIndex];
            let value = context?.dataset?.label[context.dataIndex];
            return `${value}`;
          },
        },
      },
    },
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  
  return (
    <>
      <div className="header">
        <div className="mb-[40px]">
          <div
            className="relative inline-block text-left w-full "
            ref={dropdownRef}
          >
            <button
              onClick={toggleDropdown}
              type="button"
              className="h-[44px] px-[16px] py-[12px] drop-shadow-[0px_0px_15px_rgba(152,167,167,0.08)] inline-flex justify-between w-full items-center rounded-[102px]    bg-white sm:text-[16px] text-nowrap text-[14px] font-lato-regular-400  focus:outline-none  focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            >
              {selectedOption || 'Durations of yearly return'}
              <svg
                className={`-mr-1 ml-2 h-5 w-5 transition duration-300 ease-in-out ${
                  isOpen ? "transform rotate-180" : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {isOpen && (
              <div className="  drop-shadow-[0px_0px_15px_rgba(152,167,167,0.08)] origin-top-right absolute right-0 mt-2 w-full rounded-[12px] shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div
                  className="py-1  "
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                   {generateDropdownOptions(noOfInvestementYear)?.map(
                    (option,index) => (
                      <button
                        key={option.value}
                        onClick={() => handleOptionClick(option.value)}
                        className={`block px-4 py-2 w-full  text-left text-[16px] text-gray transition duration-300 ease-in-out`}
                        role="menuitem"
                      >
                        <p
                          className={`${index === 2 ? "" :"pb-2 border-b"} border-gray-light w-full`}
                        >
                          {option.label}
                        </p>
                      </button>
                    )
                  )}
                  {/* <button
                    onClick={() => handleOptionClick("0-15")}
                    className={`block px-4 py-2 w-full  text-left text-[16px] text-gray transition duration-300 ease-in-out`}
                    role="menuitem"
                  >
                    <p className={`  pb-2 border-b border-gray-light w-full`}>
                      0-15
                    </p>
                  </button>
                  <button
                    onClick={() => handleOptionClick("0-30")}
                    className="block px-4 text-[16px] text-left w-full text-gray transition duration-300 ease-in-out"
                    role="menuitem"
                  >
                    <p className="border-b pb-2 border-gray-light w-full">
                      0-30
                    </p>
                  </button>

                  <button
                    onClick={() => handleOptionClick("All")}
                    className="block px-4 py-2 text-[16px] text-left  w-full text-gray transition duration-300 ease-in-out"
                    role="menuitem"
                  >
                    <p className=" w-full"> All</p>
                  </button> */}
                </div>
              </div>
            )}
          </div>
        </div>
        <div id="legend" className="sm:w-[305px] sm:h-[305px] w-[250px] h-[250px] m-auto">
          <Doughnut data={data} options={options} />
        </div>
        <div>
          <div className="flex justify-between items-center mt-[40px]">
            <div className="flex items-center">
              <div className="bg-[#57C6F4] h-[20px] w-[20px] rounded-[4px] mr-[12px]"></div>
              <div className="text-[16px] text-black font-lato-regular-400 text-left">
                Total Investment
              </div>
            </div>
            <div className="text-[16px] text-black font-lato-semibold-600">
              ₹ {totalInvestment ? FormatIndianNumber(totalInvestment) : 0}
            </div>
          </div>
          <div className="flex justify-between mt-[22px]">
            <div className="flex items-center">
              <div className="bg-[#FFC566] h-[20px] w-[20px] rounded-[4px] mr-[12px]"></div>
              <div className="text-[16px] text-black font-lato-regular-400 text-left">
                Est. Return
              </div>
            </div>
            <div className="text-[16px] text-black font-lato-semibold-600">
              ₹ {yearlyReturnTotal ? FormatIndianNumber(yearlyReturnTotal) : 0}
            </div>
          </div>
          <div className="flex justify-between mt-[22px]">
            <div className="flex items-center">
              <div className="bg-[#FF8566] h-[20px] w-[20px] rounded-[4px] mr-[12px]"></div>
              <div className="text-[16px] text-black font-lato-regular-400 text-left">
                Total Withdrawal
              </div>
            </div>
            <div className="text-[16px] text-black font-lato-semibold-600">
              ₹ {totalWithDrawal ? FormatIndianNumber(totalWithDrawal) : 0}
            </div>
          </div>
          <div className="flex justify-between mt-[22px]">
            <div className="flex items-center">
              <div className="bg-[#76C576] h-[20px] w-[20px] rounded-[4px] mr-[12px]"></div>
              <div className="text-[16px] text-black font-lato-regular-400 text-left">
                Est. Return after SWP
              </div>
            </div>
            <div className="text-[16px] text-black font-lato-semibold-600">
              ₹{" "}
              {yearlyReturnAfterSWPTotal
                ? FormatIndianNumber(yearlyReturnAfterSWPTotal)
                : 0}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default ChartComponent;
