export function FormatIndianNumber(amount) {
    // Convert the amount to a number if it's not already
    amount = parseFloat(amount);
    
    // Check if the number is valid
    if (isNaN(amount)) {
        return 0;
    }

    // Round the amount to the nearest integer
    amount = Math.round(amount);

    // Remove the decimal part
    amount = Math.floor(amount);

    // Format the number with commas separating the thousands
    return amount.toLocaleString('en-IN');
}


