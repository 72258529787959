import React from "react";

const Tab = ({ tabs, activeTab, setActiveTab, setShowTable,resetAllValues }) => {
  return (
    <>
      <div className="md:max-w-[376px] w-full select-none">
        <div className="flex rounded-[100px] bg-[#F6F8FB] p-[8px]">
          {tabs?.map((tab, index) => (
            <button
              key={index}
              className={`${
                activeTab === index
                  ? "bg-[#6592CB]  rounded-[100px]  text-white font-lato-semibold-600 flex justify-center items-center"
                  : "bg-transparent text-[#6F7373]"
              } flex-grow text-center sm:py-[7px] py-[8px] sm:px-[36px] sm:text-[18px]  text-[16px] font-lato-medium-400 `}
              onClick={() => {
                setActiveTab(index);
                setShowTable(false);
                resetAllValues();
              }}
            >
              {tab?.title}
          </button>
          ))}
        </div>
      </div>
      <div className="sm:mt-[32px] mt-[24px] h-full pb-[40px]">{tabs[activeTab].content}</div>
    </>
  );
};

export default Tab;
