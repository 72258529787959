import React from "react";
import Container from "./Container";
import GrowLogo from "../assets/images/grow-logo.svg";
import AppStore from "../assets/images/app-store.svg";
import GooglePlay from "../assets/images/google-play.svg";
import { Link } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import Cookies from "js-cookie";
import { parseCookie } from "../utiles/parseCookie";
const Footer = () => {
    const cookieAccept = parseCookie('grow-cookies')
  const links = [
    { to: "/history", text: "History" ,analyticsName:'history'},
    { to: "/privacy-policy", text: "Privacy Policy" ,analyticsName:'privacy_policy'},
    { to: "/terms-of-use", text: "Terms & Condition",analyticsName:'terms_and_condition' },
    { to: "/about-us", text: "About us" ,analyticsName:'about_us'},
    { to: "/disclaimer", text: "Disclaimer" ,analyticsName:'disclaimer'},
  ];
  return (
    <div className="bg-primary-bg sm:mt-[96px] mt-[24px] ">
      <Container>
        <div className="lg:flex block justify-between items-center pt-[37px]  ">
          <div className="flex lg:justify-start justify-center sm:p-0">
            <Link to="/">
              {" "}
              <img
                src={GrowLogo}
                alt="Grow-Logo"
                className="md:h-auto h-[44px]"
              />
            </Link>
          </div>
          <div className="lg:flex block justify-between text-center items-center sm:text-[18px] text-[16px] lg:gap-[40px] md:gap-[20px] gap-[10px]">
            {links?.map((link, index) => (
              <div
                key={index}
                className={`lg:mt-0 mt-${
                  index === 0 ? "[20px]" : "[12px]"
                } font-lato-regular-400 sm:text-[18px] text-[16px]`}
              >
                <Link  onClick={()=>cookieAccept && logEvent(analytics,`click_${link?.analyticsName}`) }  to={link?.to}>{link?.text}</Link>
              </div>
            ))}
          </div>
          <div className="flex md:justify-around  justify-center items-center md:gap-[10px] sm:gap-[40px] gap-[20px] md:mt-0 mt-[20px]">
            <div>
              <button>
              <a href="https://apps.apple.com/us/app/grow-life-fund/id6496204376" target="_blank">
                <img
                  src={AppStore}
                  alt="App-Store-Icon"
                  className="md:h-auto h-[36px]"
                />
                </a>
              </button>
            </div>
            <div>
            <span className="group relative">
              <button> 
                <img
                  src={GooglePlay}
                  alt="Google-Store-Icon"
                  className="md:h-auto h-[36px]"
                  />
                  <span className={` absolute hidden font-lato-regular-400 group-hover:flex justify-center  sm:-top-[35px] -top-[29px] -translate-y-full w-[120px]  m-auto px-3 py-3 bg-gray rounded-lg text-center text-white text-[14px] after:content-[''] after:absolute  after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-[transparent] after:border-b-[transparent] after:border-t-gray`}>
                  Coming soon 
                </span>
              </button>
                  </span>
            </div>
          </div>
        </div>
        <div className="border-b-2  border-[#DCE5E5] pb-[29px] ">
          <div className="text-gray-font sm:text-[16px] text-[14px] md:text-left text-center font-lato-semibold-600 sm:pt-[28px] pt-[20px]">
            Disclaimer:
          </div>
          <div className="text-gray-font  sm:text-[14px] text-[12px] font-lato-regular-400 pt-[8px]">
            Grow Life Fund's calculator is for informational purposes only and
            does not provide financial advice. It assists users in making
            approximate calculations for SIP and SWP investments. The calculated
            values are estimates and may not reflect actual returns. The
            calculator does not guarantee specific investment results or
            outcomes. Users should consult a qualified financial advisor before
            making investment decisions. The website/app owner and operators are
            not liable for any losses or damages resulting from reliance on the
            calculated values.
          </div>
        </div>
        <div className="text-center sm:pt-[40px] md:pb-[40px] pt-[20px] pb-[20px] sm:text-[18px] text-[12px] text-gray-font font-lato-regular-400">
          @2024 copyright. All rights reserved
        </div>
      </Container>
    </div>
  );
};

export default Footer;
