import React from "react";
import Container from "../components/Container";
import HeaderBanner from "../components/HeaderBanner";

const AboutUs = () => {
  return (
    <Container>
      <div className="my-[24px] ">
        <HeaderBanner name="About Us" backUrl='/'/>
        <div>
          <p className="text-[#747878] sm:text-[16px] text-[14px] font-lato-regular-400">
            Welcome to Grow Life Fund
          </p>
          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            Empowering Your Financial Journey with Advanced Insights
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 mt-[20px]">
            At Grow Life Fund, we're more than just a website and app; we're
            your next step towards financial clarity and empowerment. Located at
            the intersection of technology and finance, Grow Life Fund is
            dedicated to simplifying complex financial decisions through our
            state-of-the-art Systematic Investment Plan (SIP) and Systematic
            Withdrawal Plan (SWP) calculators.
          </p>

          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-semibold-600 mt-[20px] underline ">
            Our Mission
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-medium-500 ">
            To Illuminate Paths, To Empower Decisions
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 mt-[20px]">
            Our mission is to demystify financial planning, making it accessible
            and understandable for everyone. Whether you're planning for
            retirement, saving for a big purchase, or just looking to understand
            your financial landscape, our tools are designed to give you the
            insights you need to make informed decisions without the hassle.
          </p>

          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-semibold-600 mt-[20px] underline ">
            Our Vision
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-medium-500 ">
            A Future Where Financial Planning is Accessible to All
          </p>

          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 mt-[20px]">
            We envision a world where everyone, regardless of their financial
            literacy level, has access to the tools and information necessary to
            achieve their financial goals. Grow Life Fund is committed to being
            a beacon of guidance and reliability in your financial journey,
            providing informational resources that inspire confidence and
            action.
          </p>

          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-semibold-600 mt-[20px] underline ">
            Why Choose Us?
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 ">
           <span className=" font-lato-medium-500"> User Anonymity and Privacy:</span> Engage with our tools without the worry
            of compromising your personal information. Your privacy is
            paramount, and our platform ensures your financial explorations
            remain confidential.
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 ">
          <span className=" font-lato-medium-500"> Cutting-Edge Calculators: </span>Our advanced SIP and SWP calculators offer
            you a glimpse into the future of your investments, enabling you to
            plan with precision.
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 ">
          <span className=" font-lato-medium-500">Expertly Designed for Everyone:</span> Whether you're a seasoned investor
            or taking your first steps into financial planning, our platform is
            tailored to meet you where you are.
          </p>

          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-semibold-600 mt-[20px] underline ">
            Connect With Us
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-medium-500 ">
            Your journey towards financial empowerment starts here. Explore Grow
            Life Fund today and take the first step towards unlocking your
            financial potential. For inquiries, suggestions, or a deeper
            conversation about how we can assist you, reach out to us at: contact(at)growlifefund(dot)com.
          </p>

          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 mt-[20px]">
            Dive into the future of financial planning with Grow Life Fund -
            Where your financial well-being is our top priority.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default AboutUs;
