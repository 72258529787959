import React, {  useLayoutEffect, useRef } from "react";
// Ignore the import from under build/esm/index,
// it's due to a codesandbox issue.
import { StickyTableHeader } from 'vh-sticky-table-header'

const TableWithStickyHeader = ({ children }) => {
  const tableRef = useRef(null);
  const tableCloneRef = useRef(null);

  useLayoutEffect(() => {
    if (tableRef.current && tableCloneRef.current) {
      // Initialize the sticky header.
      const sticky = new StickyTableHeader(
        tableRef.current,
        tableCloneRef.current,
        { max: 0 }
      );
      // Make sure to destory the sticky header once the main table is unmounted.
      return () => sticky.destroy();
    }
  }, []);

  return (
    <>
      <div className="table_container">
        <table   id="table-to-download" ref={tableRef}>{children}</table>
      </div>
      <div className="clone_table table_container">
        <table  id="table-to-download" ref={tableCloneRef} />
      </div>
    </>
  );
};

export default TableWithStickyHeader;
