import React from "react";
import Container from "../components/Container";
import { useLocation } from "react-router-dom";
import Table from "../components/Table";
import HeaderBanner from "../components/HeaderBanner";
const HistoryData = () => {
  const location = useLocation();
  const rowString = location?.state;
  const historyData = JSON.parse(decodeURIComponent(rowString));

  return (
    <div>
      <Container>
        <div className="my-[24px] xl:mx-0 sm:mx-[20px] mx-0">
        <HeaderBanner name="History Data" backUrl='/history'/>
        </div>
      </Container>
      <div className="mt-[24px]">
        <Table historyData={historyData} />
      </div>
    </div>
  );
};

export default HistoryData;
