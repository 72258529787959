import React, { useEffect, useRef, useState } from "react";
import Input from "./Input";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import InfoIcon from "../assets/images/Info-icon.svg";
import {
  setAddAmountSIP,
  setAge,
  setApproxReturn,
  setErrorsEmpty,
  setIncrementPerYear,
  setIsOpen,
  setMonthlyInvestment,
  setNoOfInvestementYear,
  setRemoveFields,
  setSipErrors,
  setSwpStartYear,
  setYearlyIncrementInWithdrawSwp,
  setYearlyWithdraw,
} from "../redux/calculations/slice";
import { ButtonOutlined } from "./ButtonOutlined";
import { Button } from "./Button";
import { FormatIndianNumber } from "../utiles/FormatIndianNumber";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import { setRemoveLumpsumFields } from "../redux/calculations/lumpsumSlice";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { parseCookie } from "../utiles/parseCookie";

const SipAndSwp = ({ activeTab, setShowTable, generateData ,resetAllValues}) => {
  const cookieAccept = parseCookie('grow-cookies');
  const demoRef = useRef();
  const [pageKey, setPageKey] = useState(0);
  const location = useLocation()
  const [existingData, setExistingData] = useState(
    JSON.parse(localStorage.getItem("SIPData")) || []
  );
  const dispatch = useDispatch();
  const {
    age,
    monthlyInvestment,
    incrementPerYear,
    approxReturn,
    noOfInvestementYear,
    swpStartYear,
    yearlyWithdraw,
    yearlyIncrementInWithdrawSwp,
    addAmountSIP,
    investmentAmounts,
    sipErrors
  } = useSelector((state) => state.sip);

  const totalFreeAmount = investmentAmounts?.reduce(
    (accumulator, currentValue) => accumulator + Number(currentValue),
    0
  );

  const openModal = () => {
    dispatch(setIsOpen(true));
  };
  const handleAddButtonClick = () => {
    setShowTable(false);
    openModal();
    dispatch(setAddAmountSIP(true));
  };

  const validationSchema = Yup.object().shape({
    age: Yup.number()
      .typeError("Age must be a number")
      .required("*Age is required.")
      .min(1, "Age must be range from 0 to 99")
      .max(99, "Age must be range from 0 to 99"),
    monthlyInvestment: Yup.number()
      .required("*Monthly investment is required")
      .typeError("Monthly investment must be a number")
      .min(100, "Minimum monthly investment is 100")
      .max(1000000, "Monthly investment must not exceed 10 lacs"),
    incrementPerYear: Yup.number()
      .default(0)
      .typeError("Increment per year must be a number")
      .min(0, "Enter annual increment %, in a range of 0 to 100")
      .max(100, "Enter annual increment %, in a range of 0 to 100"),
    approxReturn: Yup.number()
      .required("*Approximate return is required")
      .typeError("Approximate return must be a number")
      .min(0, "Enter approx. return must be range from 0 to 99")
      .max(99, "Enter approx. return must be in range of 0 to 99"),
    noOfInvestementYear: Yup.number()
      .required("*Number of investment years is required")
      .typeError("Number of investment years must be a number")
      .min(1, "Investment year must to be between 1 and 45")
      .max(45, "Investment year must to be between 1 and 45"),
    swpStartYear: Yup.number()
      .default(0)
      .typeError("SWP start year must be a number")
      .min(0, "SWP Start Year must be between 0 to 45")
      .max(45, "SWP Start Year must be between 0 to 45"),
    yearlyWithdraw: Yup.number()
      .typeError("Yearly withdraw must be a number")
      .default(0)
      .min(0, "Yearly Withdraw must be greater than 0")
      .max(10000000, "Yearly Withdraw  must not exceed 1 crore"),
    yearlyIncrementInWithdrawSwp: Yup.number()
      .typeError("Yearly increment in withdraw must be a number")
      .default(0)
      .min(0, "Enter annual increment %, in a range of 0 to 100")
      .max(100, "Enter annual increment %, in a range of 0 to 100"),
  });

  useEffect(() => {
    resetAllValues()
    setPageKey(prevKey => prevKey + 1);
  }, [location.pathname])

  return (
    <>
      <div>
        <Formik
         key={pageKey}
          innerRef={demoRef}
          initialValues={{
            age: age.toString(),
            monthlyInvestment: monthlyInvestment.toString(),
            incrementPerYear: incrementPerYear.toString(),
            approxReturn: approxReturn.toString(),
            noOfInvestementYear: noOfInvestementYear.toString(),
            swpStartYear: swpStartYear.toString(),
            yearlyWithdraw: yearlyWithdraw.toString(),
            yearlyIncrementInWithdrawSwp:
            yearlyIncrementInWithdrawSwp.toString(),
          }}
          validateOnBlur
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const data = await generateData();
            cookieAccept && logEvent(analytics,`calculate_SIP`)
            // Check if data is a duplicate based on stringified objects
            const isDuplicate = existingData?.some((item) => {
              return JSON.stringify(item) === JSON.stringify(data);
            });

            if (!isDuplicate) {
              // If data is not a duplicate, update existingData
              const updatedData = [...existingData, data];
              if (updatedData.length > 20) {
                updatedData.shift(); // Remove the oldest set of data
              }
              setExistingData(updatedData);
              // Update localStorage
              localStorage.setItem("SIPData", JSON.stringify(updatedData));
            }
              setShowTable(false);
          }}
        >
          {({
            // values,
            errors,
            touched,
            dirty,
            isValid,
            handleSubmit,
            handleReset,
            setFieldValue,
            handleBlur,
          }) => {
            dispatch(setSipErrors(isValid));
            return (
              <>
                <div className="grid grid-cols-2 grid-flow-col gap-4">
                  {/* <div className=""> */}
                  <div>
                    <Input
                      name="age"
                      label="Age"
                      leftIcon="Yr"
                      value={age}
                      type="number"
                      onBlur={handleBlur}
                      placeholder={"24"}
                      onChange={(e) => {
                        if (/^\d{0,2}$/.test(e.target.value)) {
                          setFieldValue("age", e.target.value);
                          dispatch(setAge(e.target.value));
                        }
                        dispatch(setSipErrors(errors));
                      }}
                      borderColor={
                        errors.age && touched.age
                          ? " border-danger"
                          : "border-primary"
                      }
                    />
                    {errors.age && touched.age ? (
                      <p
                        className={
                          "text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"
                        }
                      >
                        {errors.age}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <Input
                      name="monthlyInvestment"
                      label="Monthly Investment"
                      leftIcon="₹"
                      type="text"
                      value={monthlyInvestment ||""}
                      onBlur={handleBlur}
                      placeholder={"10,000"}
                      onChange={(e) => {
                        dispatch(setSipErrors(errors));
                        const inputVal = e.target.value.replace(/\D/g, "");
                        let formattedValue =
                          Number(inputVal).toLocaleString("en-IN");
                        if (inputVal === "") {
                          formattedValue = "";
                        }

                        setFieldValue("monthlyInvestment", inputVal);
                        dispatch(setMonthlyInvestment(formattedValue));
                      }}
                      borderColor={
                        errors.monthlyInvestment && touched.monthlyInvestment
                          ? " border-danger"
                          : "border-primary"
                      }
                    />
                    {errors.monthlyInvestment && touched.monthlyInvestment ? (
                      <p
                        className={
                          "text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"
                        }
                      >
                        {errors.monthlyInvestment}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="grid grid-cols-2 grid-flow-col gap-4 sm:mt-[22px] mt-[20px]">
                  <div>
                    <Input
                      name="incrementPerYear"
                      label="Increment Per Year"
                      leftIcon="%"
                      value={incrementPerYear}
                      type="number"
                      onBlur={handleBlur}
                      placeholder={"12"}
                      onChange={(e) => {
                        dispatch(setSipErrors(errors));
                        setFieldValue("incrementPerYear", e.target.value);
                        dispatch(setIncrementPerYear(e.target.value));
                      }}
                      borderColor={
                        errors.incrementPerYear && touched.incrementPerYear
                          ? " border-danger"
                          : "border-primary"
                      }
                    />
                    {errors.incrementPerYear && touched.incrementPerYear ? (
                      <p
                        className={
                          "text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"
                        }
                      >
                        {errors.incrementPerYear}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <Input
                      type="number"
                      name="approxReturn"
                      label="Approx. Return"
                      leftIcon="%"
                      value={approxReturn}
                      onBlur={handleBlur}
                      placeholder={"10"}
                      onChange={(e) => {
                        dispatch(setSipErrors(errors));
                        setFieldValue("approxReturn", e.target.value);
                        dispatch(setApproxReturn(e.target.value));
                      }}
                      borderColor={
                        errors.approxReturn && touched.approxReturn
                          ? " border-danger"
                          : "border-primary"
                      }
                    />
                    {errors.approxReturn && touched.approxReturn ? (
                      <p
                        className={
                          "text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"
                        }
                      >
                        {errors.approxReturn}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="grid grid-cols-1 grid-flow-col gap-4 sm:mt-[22px] mt-[20px]">
                  <div>
                    <Input
                      type="number"
                      name="noOfInvestementYear"
                      label="No. of Investment year "
                      leftIcon="Yr"
                      value={noOfInvestementYear}
                      onBlur={handleBlur}
                      placeholder={"10"}
                      onChange={(e) => {
                        if (/^\d{0,2}$/.test(e.target.value)) {
                          dispatch(setSipErrors(errors));
                          setFieldValue("noOfInvestementYear", e.target.value);
                          dispatch(setNoOfInvestementYear(e.target.value));
                        }
                      }}
                      borderColor={
                        errors.noOfInvestementYear &&
                        touched.noOfInvestementYear
                          ? " border-danger"
                          : "border-primary"
                      }
                    />
                    {errors.noOfInvestementYear &&
                    touched.noOfInvestementYear ? (
                      <p
                        className={
                          "text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"
                        }
                      >
                        {errors.noOfInvestementYear}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="flex justify-between  items-center mt-[20px]">
                  <div className="font-lato-semibold-600 text-black sm:text-[20px] text-[16px] text-left">
                    Add Lumpsum Amount
                    {activeTab === 0 && addAmountSIP && (
                      <button
                        disabled={errors.noOfInvestementYear ? true : false}
                        className="ml-2 text-[#0065FD] font-lato-semibold-600 sm:text-[20px] text-[16px]"
                        onClick={handleAddButtonClick}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                  <div>
                    {activeTab === 0 && !addAmountSIP ? (
                      <ButtonOutlined
                        disabled={errors.noOfInvestementYear ? true : false}
                        onClick={() => openModal()}
                        className="sm:min-w-[160px] min-w-[120px]"
                      >
                        ADD
                      </ButtonOutlined>
                    ) : (
                      activeTab === 0 &&
                      addAmountSIP && (
                        <ButtonOutlined
                          icon="₹"
                          disabled={true}
                          className="sm:min-w-[160px] min-w-[120px]"
                        >
                          {totalFreeAmount
                            ? FormatIndianNumber(totalFreeAmount)
                            : 0}
                        </ButtonOutlined>
                      )
                    )}
                  </div>
                </div>
                <div className="border-b border-gray-light mt-[20px]"></div>
                <div className="grid grid-cols-2 grid-flow-col gap-4 mt-[20px] ">
                  <div>
                    <Input
                      // labelicon={InfoIcon}
                      // tooltipClassName={'-left-[45px] after:left-[25%]'}
                      // tooltipContent={'Systematic Withdrawal Plan (SWP) is a mutual fund withdrawal plan, if you invest a lumpsum or SIP in a mutual fund, you can set an amount you’ll withdraw regularly and the frequency at which you’ll withdraw.'}
                      type="number"
                      name="swpStartYear"
                      label="SWP Start Year"
                      leftIcon="Yr"
                      value={swpStartYear}
                      onBlur={handleBlur}
                      placeholder={"10"}
                      onChange={(e) => {
                        setFieldValue("swpStartYear", e.target.value);
                        dispatch(setSwpStartYear(e.target.value));
                        dispatch(setSipErrors(errors));
                      }}
                      borderColor={
                        errors.swpStartYear && touched.swpStartYear
                          ? " border-danger"
                          : "border-primary"
                      }
                    />
                    {errors.swpStartYear && touched.swpStartYear ? (
                      <p
                        className={
                          "text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"
                        }
                      >
                        {errors.swpStartYear}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <Input
                     labelicon={InfoIcon}
                     tooltipClassName={'sm:after:left-[79%] sm:-left-[180px] after:left-[90%] -left-[208px]'}
                     tooltipContent={'Systematic Withdrawal Plan (SWP) is a mutual fund withdrawal plan, if you invest a lumpsum or SIP in a mutual fund, you can set an amount you’ll withdraw regularly and the frequency at which you’ll withdraw.'}
                      name="yearlyWithdraw"
                      label="Yearly Withdraw"
                      leftIcon="₹"
                      value={yearlyWithdraw}
                      type="text"
                      onBlur={handleBlur}
                      placeholder={"50,000"}
                      onChange={(e) => {
                        dispatch(setSipErrors(errors));
                        const inputVal = e.target.value.replace(/\D/g, "");
                        let formattedValue =
                          Number(inputVal).toLocaleString("en-IN");
                        if (inputVal === "") {
                          formattedValue = "";
                        }
                        setFieldValue("yearlyWithdraw", inputVal);
                        dispatch(setYearlyWithdraw(formattedValue));
                      }}
                      borderColor={
                        errors.yearlyWithdraw && touched.yearlyWithdraw
                          ? " border-danger"
                          : "border-primary"
                      }
                    />
                    {errors.yearlyWithdraw && touched.yearlyWithdraw ? (
                      <p
                        className={
                          "text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"
                        }
                      >
                        {errors.yearlyWithdraw}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="grid grid-cols-1 grid-flow-col gap-4 sm:mt-[22px] mt-[20px]">
                  <div>
                    <Input
                      type="number"
                      name="yearlyIncrementInWithdrawSwp"
                      label="Yearly Increment in Withdraw"
                      leftIcon="%"
                      value={yearlyIncrementInWithdrawSwp}
                      onBlur={handleBlur}
                      placeholder={"5"}
                      onChange={(e) => {
                        setFieldValue(
                          "yearlyIncrementInWithdrawSwp",
                          e.target.value
                        );

                        dispatch(
                          setYearlyIncrementInWithdrawSwp(e.target.value)
                        );
                        dispatch(setSipErrors(errors));
                      }}
                      borderColor={
                        errors.yearlyIncrementInWithdrawSwp &&
                        touched.yearlyIncrementInWithdrawSwp
                          ? " border-danger"
                          : "border-primary"
                      }
                    />
                    {errors.yearlyIncrementInWithdrawSwp &&
                    touched.yearlyIncrementInWithdrawSwp ? (
                      <p
                        className={
                          "text-danger sm:text-[14px] text-[12px] font-lato-medium-500  text-left pl-2"
                        }
                      >
                        {errors.yearlyIncrementInWithdrawSwp}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="border-b border-gray-light mt-[20px]"></div>
                {/* </div> */}
                <div className=" lg:mt-[50px] mt-[20px]">
                  {" "}
                  <Button
                    type={"submit"}
                    className="w-full"
                    onClick={handleSubmit}
                  >
                    Calculate
                  </Button>
                </div>
                {/* </Form> */}
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default SipAndSwp;
