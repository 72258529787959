import React, { useEffect, useRef, useState } from "react";
import Input from "./Input";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import InfoIcon from "../assets/images/Info-icon.svg";
import {
  setAge,
  setApproxReturn,
  setTotalInvestment,
  setLumErrors,
  setSwpStartYear,
  setYearlyIncrementInWithdrawSwp,
  setYearlyWithdraw,
} from "../redux/calculations/lumpsumSlice";
import { Button } from "./Button";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { parseCookie } from "../utiles/parseCookie";

const Lumpsum = ({  setShowTable, generateData ,resetAllValues}) => {
  const cookieAccept = parseCookie('grow-cookies');
  const [pageKey, setPageKey] = useState(0);
  const location = useLocation()
  const [existingData, setExistingData] = useState(
    JSON.parse(localStorage.getItem("SIPData")) || []
  );

  const demoRef = useRef();
  const dispatch = useDispatch();
  const {
    age,
    monthlyInvestment,
    incrementPerYear,
    approxReturn,
    noOfInvestementYear,
    swpStartYear,
    yearlyWithdraw,
    yearlyIncrementInWithdrawSwp,
  } = useSelector((state) => state.lum);

  // const totalFreeAmount = investmentAmounts?.reduce(
  //   (accumulator, currentValue) => accumulator + Number(currentValue),
  //   0
  // );

  // const openModal = () => {
  //   dispatch(setIsOpenLum(true));
  // };
  // const handleAddButtonClick = () => {
  //   setShowTable(false);
  //   openModal();
  //   dispatch(setAddAmountSIPLum(true));
  // };

  const validationSchema = Yup.object().shape({
    age: Yup.number()
    .typeError("Age must be a number")
    .required("*Age is required.")
    .min(1, "Age must be in range of 0 to 99")
    .max(99, "Age  must be in range of 0 to 99"),
    totalInvestment: Yup.number()
    .required("*Total investment is required")
    .typeError("Total investment must be a number")
    .min(5000, "Minimum Total investment is 5000")
    .max(100000000, "Total investment must not exceed 10 crore"),
  approxReturn: Yup.number()
    .required("*Approximate return is required")
    .typeError("Approximate return must be a number")
    .min(0, "Enter approx. return must be range from 0 to 99")
    .max(99, "Enter approx. return must be in range of 0 to 99"),
  swpStartYear: Yup.number()
    .default(0)
    .typeError("SWP start year must be a number")
    .min(0, "SWP Start Year must be between 0 to 45")
    .max(45, "SWP Start Year must be between 0 to 45"),
  yearlyWithdraw: Yup.number()
    .typeError("Yearly withdraw must be a number")
    .default(0)
    .min(0, "Yearly Withdraw must be greater than 0")
    .max(100000000, "Yearly Withdraw  must not exceed 10 crore"),
  yearlyIncrementInWithdrawSwp: Yup.number()
    .typeError("Yearly increment in withdraw must be a number")
    .default(0)
    .min(0, "Enter annual increment %, in a range of 0 to 100")
    .max(100, "Enter annual increment %, in a range of 0 to 100"),
  });
  useEffect(() => {
    resetAllValues()
    setPageKey(prevKey => prevKey + 1);
  }, [location.pathname])

  return (
    <div className=" flex flex-col h-full">
      <Formik
        key={pageKey}
        innerRef={demoRef}
        initialValues={{
          age: age.toString(),
          totalInvestment: monthlyInvestment.toString(),
          incrementPerYear: incrementPerYear.toString(),
          approxReturn: approxReturn.toString(),
          noOfInvestementYear: noOfInvestementYear.toString(),
          swpStartYear: swpStartYear.toString(),
          yearlyWithdraw: yearlyWithdraw.toString(),
          yearlyIncrementInWithdrawSwp: yearlyIncrementInWithdrawSwp.toString(),
        }}
        validationSchema={validationSchema}
        validateOnBlur
        onSubmit={async (values) => {
         cookieAccept && logEvent(analytics,`calculate_LUMPSUM`)
          const data = await generateData();
          // Check if data is a duplicate based on stringified objects
          const isDuplicate = existingData?.some((item) => {
            return JSON.stringify(item) === JSON.stringify(data);
          });
          if (!isDuplicate) {
            // If data is not a duplicate, update existingData
            const updatedData = [...existingData, data];
            if (updatedData.length > 20) {
              updatedData.shift(); // Remove the oldest set of data
            }
            setExistingData(updatedData);
            // Update localStorage
            localStorage.setItem("SIPData", JSON.stringify(updatedData));
          }
      
          setShowTable(false);
        }}
      >
        {({
          // values,
          errors,
          touched,
          dirty,
          isValid,
          handleSubmit,
          handleReset,
          setFieldValue,
          handleBlur,
        }) => {
          dispatch(setLumErrors(isValid));
          return (
            <div className=" flex flex-col h-full">
              <div className="grid grid-cols-2  grid-flow-col gap-4 relative">
                <div >
                  <Input
                    name="age"
                    label="Age"
                    leftIcon="Yr"
                    value={age}
                    type="number"
                    onBlur={handleBlur}
                    placeholder={"24"}
                    onChange={(e) => {
                      if (/^\d{0,2}$/.test(e.target.value)) {
                        setFieldValue("age", e.target.value);
                        dispatch(setAge(e.target.value));
                      }
                      dispatch(setLumErrors(errors));
                    }}
                    borderColor={
                      errors.age && touched.age
                        ? " border-danger"
                        : "border-primary"
                    }
                  />
                  {errors.age && touched.age ? (
                    <p className={"text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"}>
                      {errors.age}
                    </p>
                  ) : null}
                </div>
                <div>
                  {" "}
                  <Input
                    name="totalInvestment"
                    label="Total Investment"
                    leftIcon="₹"
                    type="text"
                    value={monthlyInvestment}
                    onBlur={handleBlur}
                    placeholder={"10,00,000"}
                    onChange={(e) => {
                      const inputVal = e.target.value.replace(/\D/g, "");
                      let formattedValue =
                        Number(inputVal).toLocaleString("en-IN");
                      if (inputVal === "") {
                        formattedValue = "";
                      }
                      dispatch(setLumErrors(errors));
                      setFieldValue("totalInvestment", inputVal);
                      dispatch(setTotalInvestment(formattedValue));
                    }}
                    borderColor={
                      errors.totalInvestment && touched.totalInvestment
                        ? " border-danger"
                        : "border-primary"
                    }
                  />
                  {errors.totalInvestment && touched.totalInvestment ? (
                    <p className={"text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"}>
                      {errors.totalInvestment}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="grid grid-cols-1  grid-flow-col gap-4 sm:mt-[22px] mt-[20px]">
                <div>
                  {" "}
                  <Input
                    name="approxReturn"
                    label="Approx. Return"
                    leftIcon="%"
                    type="number"
                    value={approxReturn}
                    onBlur={handleBlur}
                    placeholder={"10"}
                    onChange={(e) => {
                      dispatch(setLumErrors(errors));
                      setFieldValue("approxReturn", e.target.value);
                      dispatch(setApproxReturn(e.target.value));
                    }}
                    borderColor={
                      errors.approxReturn && touched.approxReturn
                        ? " border-danger"
                        : "border-primary"
                    }
                  />
                  {errors.approxReturn && touched.approxReturn ? (
                    <p className={"text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"}>
                      {errors.approxReturn}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="border-b border-gray-light mt-[20px]"></div>
              <div className="grid grid-cols-2  grid-flow-col gap-4 mt-[20px] ">
                <div>
                  <Input
                    // labelicon={InfoIcon}
                    // tooltipClassName={'-left-[45px] after:left-[25%]'}
                    // tooltipContent={'Systematic Withdrawal Plan (SWP) is a mutual fund withdrawal plan, if you invest a lumpsum or SIP in a mutual fund, you can set an amount you’ll withdraw regularly and the frequency at which you’ll withdraw.'}
                    type="number"
                    name="swpStartYear"
                    label="SWP Start Year"
                    leftIcon="Yr"
                    value={swpStartYear}
                    onBlur={handleBlur}
                    placeholder={"10"}
                    onChange={(e) => {
                      setFieldValue("swpStartYear", e.target.value);
                      dispatch(setSwpStartYear(e.target.value));
                      dispatch(setLumErrors(errors));
                    }}
                    borderColor={
                      errors.swpStartYear && touched.swpStartYear
                        ? " border-danger"
                        : "border-primary"
                    }
                  />
                  {errors.swpStartYear && touched.swpStartYear ? (
                    <p className={"text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"}>
                      {errors.swpStartYear}
                    </p>
                  ) : null}
                </div>
                <div>
                  {" "}
                  <Input
                   labelicon={InfoIcon}
                   tooltipClassName={'after:left-[79%] -left-[180px]'}
                   tooltipContent={'Systematic Withdrawal Plan (SWP) is a mutual fund withdrawal plan, if you invest a lumpsum or SIP in a mutual fund, you can set an amount you’ll withdraw regularly and the frequency at which you’ll withdraw.'}
                    type="text"
                    name="yearlyWithdraw"
                    label="Yearly Withdraw"
                    leftIcon="₹"
                    value={yearlyWithdraw}
                    onBlur={handleBlur}
                    placeholder={"50,000"}
                    onChange={(e) => {
                      const inputVal = e.target.value.replace(/\D/g, "");
                      let formattedValue =
                        Number(inputVal).toLocaleString("en-IN");
                      if (inputVal === "") {
                        formattedValue = "";
                      }
                      setFieldValue("yearlyWithdraw", inputVal);
                      dispatch(setYearlyWithdraw(formattedValue));
                      dispatch(setLumErrors(errors));
                    }}
                    borderColor={
                      errors.yearlyWithdraw && touched.yearlyWithdraw
                        ? " border-danger"
                        : "border-primary"
                    }
                  />
                  {errors.yearlyWithdraw && touched.yearlyWithdraw ? (
                    <p className={"text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"}>
                      {errors.yearlyWithdraw}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="grid grid-cols-1 grid-flow-col gap-4 sm:mt-[22px] mt-[20px]">
                <div>
                  <Input
                    type="number"
                    name="yearlyIncrementInWithdrawSwp"
                    label="Yearly Increment in Withdraw"
                    leftIcon="%"
                    value={yearlyIncrementInWithdrawSwp}
                    onBlur={handleBlur}
                    placeholder={"5"}
                    onChange={(e) => {
                      setFieldValue(
                        "yearlyIncrementInWithdrawSwp",
                        e.target.value
                      );

                      dispatch(setYearlyIncrementInWithdrawSwp(e.target.value));
                      dispatch(setLumErrors(errors));
                    }}
                    borderColor={
                      errors.yearlyIncrementInWithdrawSwp &&
                      touched.yearlyIncrementInWithdrawSwp
                        ? " border-danger"
                        : "border-primary"
                    }
                  />
                  {errors.yearlyIncrementInWithdrawSwp &&
                  touched.yearlyIncrementInWithdrawSwp ? (
                    <p className={"text-danger sm:text-[14px] text-[12px] font-lato-medium-500 text-left pl-2"}>
                      {errors.yearlyIncrementInWithdrawSwp}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="border-b border-gray-light mt-[20px] mb-[20px]"></div>
              <div className=" mt-auto">
                {" "}
                <Button
                  type={"submit"}
                  className="w-full"
                  onClick={handleSubmit}
                >
                  Calculate
                </Button>
              </div>
              {/* </Form> */}
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default Lumpsum;
