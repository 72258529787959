import React, { useState } from "react";
import Table from "../components/Table";

import HomeBanner from "../components/HomeBanner";
import SIPBoard from "../components/SIPBoard";

const LandingPage = () => {
  const [showTable, setShowTable] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      <HomeBanner />
      <SIPBoard showTable={showTable} setShowTable={setShowTable} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="mt-[60px]">{showTable && <Table activeTab={activeTab} />}</div>
    </>
  );
};

export default LandingPage;
