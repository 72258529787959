import React from "react";
import Container from "../components/Container";
import HeaderBanner from "../components/HeaderBanner";
const PrivacyPolicy = () => {
  return (
    <Container>
      <div className="my-[24px] ">
        <HeaderBanner name="Privacy Policy" backUrl='/'/>
        <div>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            Welcome to Grow Life Fund {" "}<a href="https://growlifefund.com/" target="_blank" className="underline text-[#0000FF]">www.growlifefund.com</a>. This privacy
            policy explains how we collect, use, disclose, and safeguard your
            information when you visit our website and use our app, including
            any other media form, media channel, mobile website, or mobile
            application related or connected thereto (collectively, the “Site”).
            Please read this privacy policy carefully. If you do not agree with
            the terms of this privacy policy, please do not access the site.
          </p>
          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            Information We Collect
          </p>
          <ul className="list-disc pl-[20px]">
            <li className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
              <span className="font-lato-medium-500  text-[16px]">
                {" "}
                Analytical Information:
              </span>{" "}
              We collect information related to your use of our Site for
              analytics purposes. This may include your IP address, browser
              type, device information, and usage patterns. This information is
              used to improve the functionality and performance of our Site and
              to better understand how users interact with our services.
            </li>
          </ul>
          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            Use of Your Information
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            Having accurate information about you permits us to provide you with
            a smooth, efficient, and customized experience. Specifically, we may
            use information collected about you via the Site to:
          </p>
          <ul className="list-disc pl-[20px] text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            <li className="">
              Compile anonymous statistical data and analysis for use
              internally.
            </li>
            <li>
              Monitor and analyze usage and trends to improve your experience
              with the Site.
            </li>
          </ul>

          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            Disclosure of Your Information
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            We may share information we have collected about you in certain
            situations. Your information may be disclosed as follows:
          </p>
          <ul className="list-disc pl-[20px] text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            <li className="">
              <span className="font-lato-medium-500  text-[16px]">
                {" "}
                Third-Party Service Providers:
              </span>{" "}
              We may share your information with third parties that perform
              services for us or on our behalf, including data analysis, email
              delivery, hosting services, customer service, and marketing
              assistance.
            </li>
            <li className="">
              <span className="font-lato-medium-500  text-[16px]">
                {" "}
                Marketing Communications:
              </span>{" "}
              With your consent, we may share your information with third
              parties for marketing purposes, as permitted by law.
            </li>
            <li className="">
              <span className="font-lato-medium-500  text-[16px]">
                {" "}
                Third-Party Advertisers:
              </span>{" "}
              We may use third-party advertising companies to serve ads when you
              visit the Site. These companies may use information about your
              visits to the Site and other websites that are contained in web
              cookies in order to provide advertisements about goods and
              services of interest to you.
            </li>
          </ul>

          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            Cookies and Web Beacons
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            We are currently evaluating the use of cookies on our Site to
            enhance the user experience and may update this policy accordingly.
            Cookies are small data files stored on your hard drive or in device
            memory that help us improve our Site and your experience, see which
            areas and features of our Site are popular, and count visits.
          </p>

          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            Security of Your Information
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            We use administrative, technical, and physical security measures to
            help protect your personal information. While we have taken
            reasonable steps to secure the personal information you provide to
            us, please be aware that despite our efforts, no security measures
            are perfect or impenetrable, and no method of data transmission can
            be guaranteed against any interception or other type of misuse.
          </p>

          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            Policy for Children
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            We do not knowingly solicit information from or market to children
            under the age of 13. If you become aware of any data we have
            collected from children under age 13, please contact us using the
            information provided below.
          </p>

          <p className="text-gray-font sm:text-[18px] text-[16px] font-lato-medium-500 mt-[20px] ">
            Contact Us
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            If you have questions or comments about this Privacy Policy, please
            contact us at: contact(at)growlifefund(dot)com.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
