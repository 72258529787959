import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  age: "",
  monthlyInvestment: "",
  incrementPerYear: "",
  approxReturn: "",
  noOfInvestementYear: "1",
  swpStartYear: "",
  yearlyWithdraw: "",
  yearlyIncrementInWithdrawSwp: "",
  resultSip: "",
  isOpen: false,
  addAmountSIP: false,
  addAmountLUM: false,
  investmentDataSIP: [],
  investmentAmounts: [],
  tempInvestmentAmounts: [],
  sipErrors: null,
  selectedOption: "0-1",
  filterInvestmentData: [],
};

const sipAndSwpSlice = createSlice({
  name: "sipAndSwp",
  initialState: initialState,
  reducers: {
    setAge(state, action) {
      state.age = action?.payload;
    },
    setMonthlyInvestment(state, action) {
      state.monthlyInvestment = action.payload;
    },
    setIncrementPerYear(state, action) {
      state.incrementPerYear = action.payload;
    },
    setApproxReturn(state, action) {
      state.approxReturn = action.payload;
    },
    setNoOfInvestementYear(state, action) {
      state.noOfInvestementYear = action.payload;
    },
    setSwpStartYear(state, action) {
      state.swpStartYear = action.payload;
    },
    setYearlyWithdraw(state, action) {
      state.yearlyWithdraw = action.payload;
    },
    setYearlyIncrementInWithdrawSwp(state, action) {
      state.yearlyIncrementInWithdrawSwp = action.payload;
    },
    setResultSip(state, action) {
      state.resultSip = action.payload;
    },
    setIsOpen(state, action) {
      state.isOpen = action.payload;
    },
    setAddAmountSIP(state, action) {
      state.addAmountSIP = action.payload;
    },
    setAddAmountLUM(state, action) {
      state.addAmountLUM = action.payload;
    },
    setInvestmentDataSIP(state, action) {
      state.investmentDataSIP = action.payload;
    },
    setInvestmentAmounts(state, action) {
      state.investmentAmounts = action.payload;
    },
    setTempInvestmentAmounts(state, action) {
      state.tempInvestmentAmounts = action.payload;
    },
    setSipErrors(state, action) {
      state.sipErrors = action.payload;
    },
    setSelectedOption(state, action) {
      state.selectedOption = action.payload;
    },
    setFilterInvestmentData(state, action) {
      state.filterInvestmentData = action.payload;
    },

    setRemoveFields(state) {
      state.age = "";
      state.monthlyInvestment = "";
      state.incrementPerYear = "";
      state.approxReturn = "";
      state.swpStartYear = "";
      state.yearlyWithdraw = "";
      state.yearlyIncrementInWithdrawSwp = "";
      state.filterInvestmentData = [];
      state.investmentDataSIP = [];
      state.sipErrors=null;
      state.addAmountSIP=false;
      state.noOfInvestementYear= "1"
    },
  },
});

export const {
  setRemoveFields,
  setAge,
  setMonthlyInvestment,
  setIncrementPerYear,
  setApproxReturn,
  setNoOfInvestementYear,
  setSwpStartYear,
  setYearlyWithdraw,
  setYearlyIncrementInWithdrawSwp,
  setResultSip,
  setIsOpen,
  setAddAmountSIP,
  setAddAmountLUM,
  setInvestmentDataSIP,
  setInvestmentAmounts,
  setTempInvestmentAmounts,
  setSipErrors,
  setSelectedOption,
  setFilterInvestmentData,
} = sipAndSwpSlice.actions;
const { reducer } = sipAndSwpSlice;
export default reducer;
