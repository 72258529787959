import React from "react";
export const ButtonOutlined = ({ children, onClick, type, className, disabled, icon }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`text-center sm:p-[3px] p-[2px] rounded-full  ${disabled ? 'bg-gradient-to-r from-[#07B5B5] to-[#058282]  ' : 'gradient-outline-btn'} ${className}`}
    >
      <span className={`flex ${icon ?'justify-between' :'justify-center' }  items-center px-4 py-[7px] rounded-full gradient-outline-btn-wrap`}>
        
        <span className="sm:text-[18px] text-[16px] font-lato-bold-700 gradient-outline-btn-content">
          {children}
        </span>
        {icon && (
          <span className="ml-2 pl-2 border-l sm:text-[20px] text-[16px]font-lato-bold-700 gradient-outline-btn-icon">{icon}</span>
        )}
      </span>
    </button>
  );
};

