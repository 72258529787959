import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  age: "",
  monthlyInvestment: "",
  incrementPerYear: "",
  approxReturn: "",
  noOfInvestementYear: 1,
  swpStartYear: "",
  yearlyWithdraw: "",
  yearlyIncrementInWithdrawSwp: "",
  resultSip: "",
  isOpen: false,
  addAmountSIP: false,
  addAmountLUM: false,
  investmentDataSIP: [],
  investmentAmounts: [],
  tempInvestmentAmounts: [],
  sipErrors: null,
  selectedOption: null,
  filterInvestmentData:[]
};

const lumpsumSlice = createSlice({
  name: 'lumpsum',
  initialState: initialState,
  reducers: {
    setAge(state, action) {
      state.age = action?.payload;
    },
    setTotalInvestment(state, action) {
      state.monthlyInvestment = action.payload;
    },
    setIncrementPerYear(state, action) {
      state.incrementPerYear = action.payload;
    },
    setApproxReturn(state, action) {
      state.approxReturn = action.payload;
    },
    setNoOfInvestementYear(state, action) {
      state.noOfInvestementYear = action.payload;
    },
    setSwpStartYear(state, action) {
      state.swpStartYear = action.payload;
    },
    setYearlyWithdraw(state, action) {
      state.yearlyWithdraw = action.payload;
    },
    setYearlyIncrementInWithdrawSwp(state, action) {
      state.yearlyIncrementInWithdrawSwp = action.payload;
    },
    setResultSip(state, action) {
      state.resultSip = action.payload;
    },
    setIsOpenLum(state, action) {
      state.isOpen = action.payload;
    },
    setAddAmountSIPLum(state, action) {
      state.addAmountSIP = action.payload;
    },
    setAddAmountLUM(state, action) {
      state.addAmountLUM = action.payload;
    },
    setInvestmentDataLUM(state, action) {
      state.investmentDataSIP = action.payload;
    },
    setInvestmentAmountsLum(state, action) {
      state.investmentAmounts = action.payload;
    },
    setTempInvestmentAmountsLum(state, action) {
      state.tempInvestmentAmounts = action.payload;
    },
    setLumErrors(state, action) {
      state.sipErrors = action.payload;
    },
    setSelectedOptionLum(state, action) {
      state.selectedOption = action.payload;
    },
    setFilterInvestmentDataLum(state, action) {
      state.filterInvestmentData= action.payload;
    },
    setRemoveLumpsumFields(state) {
      state.age = "";
      state.monthlyInvestment = "";
      state.incrementPerYear = "";
      state.approxReturn = "";
      state.swpStartYear = "";
      state.yearlyWithdraw = "";
      state.yearlyIncrementInWithdrawSwp = "";
      state.filterInvestmentData = [];
      state.investmentDataSIP = [];
      state.sipErrors=null;
      state.addAmountSIP=false;
      state.noOfInvestementYear= "1"
    },
  }
});

export const {setRemoveLumpsumFields, setAge, setTotalInvestment, setIncrementPerYear, setApproxReturn, setNoOfInvestementYear, setSwpStartYear, setYearlyWithdraw, setYearlyIncrementInWithdrawSwp, setResultSip, setIsOpenLum, setAddAmountSIPLum, setAddAmountLUM, setInvestmentDataLUM, setInvestmentAmountsLum, setTempInvestmentAmountsLum, setLumErrors,setSelectedOptionLum,setFilterInvestmentDataLum } = lumpsumSlice.actions;
const { reducer } = lumpsumSlice
export default reducer
