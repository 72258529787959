import React, { useEffect, useState } from "react";
import Container from "../components/Container";
import { Link } from "react-router-dom";
import DeleteIcon from "../assets/images/delete-icon.svg";
import { FormatIndianNumber } from "../utiles/FormatIndianNumber";
import TableWithStickyHeader from "../components/TableWithStickyHeader";
import { Button } from "../components/Button";
import HeaderBanner from "../components/HeaderBanner";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import Cookies from "js-cookie";
import { parseCookie } from "../utiles/parseCookie";

const History = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const historyDataStore = JSON.parse(localStorage.getItem("SIPData")) ?? [];
  const [historyData, setHistoryData] = useState(historyDataStore);
  const headers = ["No.", "Name", "Total Investment", "Yearly Return", "", ""];
  const cookieAccept = parseCookie('grow-cookies');
  const reversedHistoryData = [...historyData].reverse();
  
  function findLastTotalInvestment(arr) {
    for (let i = arr?.length - 1; i >= 0; i--) {
      // Check if the 'totalInvestment' property is not empty
      const totalInvestment = arr[i].totalInvestment;
      if (typeof totalInvestment === "number") {
        return totalInvestment;
      }
    }
    return null; // Return null if no non-empty 'totalInvestment' value is found
  }

  function getLastYearlyReturns(data) {
    return data.map((array) => {
      if (array?.length > 0) {
        const lastObject = array[array?.length - 1];
        return lastObject.yearlyReturn || 0; // Return yearlyReturn if it exists, otherwise return 0
      } else {
        return 0; // If the array is empty, return 0
      }
    });
  }
 
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isModalOpen]);
  const handleDeleteClick = (index) => {
    setDeleteIndex(index);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
   cookieAccept && logEvent(analytics,`delete_report`)
   const originalIndex = historyData.length - 1 - deleteIndex;
    const updatedHistoryData = [...historyData];
    updatedHistoryData.splice(originalIndex, 1);
    localStorage.setItem("SIPData", JSON.stringify(updatedHistoryData));
    setHistoryData(updatedHistoryData);
    setIsModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <div className="my-[24px]">
        <HeaderBanner name="History" backUrl='/'/>
        <div className="overflow-x-auto pb-[20px] ">
          <TableWithStickyHeader className="min-w-full ">
            <thead className="bg-primary-bg text-left ">
              <tr className="rounded-[8px]">
                {headers?.map((header, index) => (
                  <th
                    key={index}
                    className={`px-[12px] py-[14px] sm:text-[16px] text-[14px] text-gray-font font-lato-regular-400  ${
                      index === 0
                        ? "rounded-l-[8px]"
                        : index === headers?.length - 1
                        ? "rounded-r-[8px]"
                        : ""
                    }`}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white text-left">
              {historyData?.length === 0 ? (
                <tr>
                  <td
                    colSpan="9"
                    className={`px-[12px] py-[14px] whitespace-nowrap sm:text-[20px] text-center text-[14px] text-black font-lato-regular-400`}
                  >
                    No history available, calculate your financial goal!
                  </td>
                </tr>
              ) : (
                reversedHistoryData?.map((row, index) => (
                  <tr
                    key={index}
                    className={` ${
                      index === row?.length - 1 ? "border-0" : "border-b"
                    } border-gray-light`}
                  >
                    <td
                      className={`px-[12px] pt-[22px] pb-[14px] whitespace-nowrap sm:text-[16px] text-[14px] text-black font-lato-regular-400`}
                    >
                      {index + 1}
                    </td>
                    <td
                      className={`px-[12px] pt-[22px] pb-[14px] whitespace-nowrap sm:text-[16px] text-[14px] text-black font-lato-regular-400`}
                    >
                      {row[0].tabName}
                    </td>
                    <td
                      className={`px-[12px] py-[14px] whitespace-nowrap sm:text-[16px] text-[14px] text-black font-lato-regular-400`}
                    >
                      {findLastTotalInvestment(row) == 0 ||
                      findLastTotalInvestment(row) == "0"
                        ? "-"
                        : FormatIndianNumber(findLastTotalInvestment(row))}
                    </td>
                    <td
                      className={`px-[12px] py-[14px] whitespace-nowrap sm:text-[16px] text-[14px] text-black font-lato-regular-400`}
                    >
                      {getLastYearlyReturns([row]) == 0
                        ? "-"
                        : FormatIndianNumber(getLastYearlyReturns([row]))}
                    </td>
                    <td className={`px-[12px] py-[14px]`}>
                      <Link
                       onClick={()=> cookieAccept && logEvent(analytics,`preview_report`)}
                        className="relative pb-[2px] whitespace-nowrap sm:text-[16px] text-[14px] bg-gradient-primary bg-clip-text text-[transparent] font-lato-regular-400 after:content-[''] after:absolute after:w-full after:h-[1.5px] after:bottom-0 after:left-0 after:bg-gradient-primary"
                        to={`/history/${index + 1}`}
                        state={JSON.stringify(row)}
                      >
                        Preview
                      </Link>
                    </td>
                    <td className={`px-[12px] py-[14px]`}>
                      <div
                        className="cursor-pointer font-lato-regular-400 text-[16px] text-danger"
                        onClick={() => handleDeleteClick(index)}
                      >
                        Delete
                      </div>
                      {/* <img
                        src={DeleteIcon}
                        alt="delete-icon"
                        onClick={() => handleDeleteClick(index)}
                      /> */}
                    </td>
                  </tr>
                ))
              )}

              {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center  w-full bg-black bg-opacity-50 p-[40px]">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="max-w-[503px]  overflow-auto w-full  bg-white sm:p-[32px] m-[20px] p-[20px] rounded-lg shadow-lg">
                      <div className="bg-[#FC00001A] w-[100px] h-[100px] m-auto rounded-[100%]">
                        <img
                          src={DeleteIcon}
                          alt="delete-icon"
                          className="flex justify-center p-[32px]"
                        />
                      </div>
                    
                      <div className=" mt-[20px] text-black text-center m-auto sm:text-[20px] text-[18px] sm:font-lato-medium-500 font-lato-medium-500">
                        Are you sure you want to Delete this history?
                      </div>
                     
                      <div className="sm:flex block justify-between w-full sm:mt-[42px] mt-[24px] gap-4 ">
                        <button
                          onClick={handleConfirmDelete}
                          className={`  text-center w-full  rounded-full border border-danger transition-all  bg-gradient-to-b sm:h-[48px] h-[40px] focus:outline-none`}
                        >
                          <span className="flex items-center justify-center px-4 py-2 rounded-full  sm:text-[18px] text-[16px] font-lato-bold-700 text-danger ">
                            Yes, Delete
                          </span>
                        </button>
                      
                        <Button onClick={handleCancelDelete} className="w-full sm:mt-0 mt-[10px]">
                          No, Keep
                        </Button>
                      </div>
                     
                    </div>
                  </div>
                </div>
            
              )}
            </tbody>
          </TableWithStickyHeader>
        </div>
      </div>
    </Container>
  );
};

export default History;
