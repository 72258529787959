import Cookies from "js-cookie";

export function parseCookie(cookieName) {
    const cookieValue = Cookies.get(cookieName);
    if (cookieValue === undefined) {
      return null;
    } else {
      try {
        return JSON.parse(cookieValue);
      } catch (error) {
        console.error('Error parsing cookie:', error);
        return null;
      }
    }
  }