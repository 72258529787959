import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAccepted: false
};

const cookieConsentSlice = createSlice({
  name: "cookieConsent",
  initialState: initialState,
  reducers: {
    setIsAccepted(state, action) {
      state.isAccepted = action?.payload;
    },
  },
});

export const {
  setIsAccepted,
} = cookieConsentSlice.actions;
const { reducer } = cookieConsentSlice;
export default reducer;
