import React from "react";
import Container from "../components/Container";
import HeaderBanner from "../components/HeaderBanner";

const TermsOfUse = () => {
  return (
    <Container>
      <div className="my-[24px]">
        <HeaderBanner name="Terms of Use" backUrl='/'/>

        <div>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
            Welcome to Grow Life Fund. These terms and conditions outline the
            rules and regulations for the use of Grow Life Fund's Website and
            App, located at {" "}<a href="https://growlifefund.com/" target="_blank" className="underline text-[#0000FF]">www.growlifefund.com</a>.
          </p>
          <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 mt-[20px]">
            By accessing this website and app, we assume you accept these terms
            and conditions in full. Do not continue to use Grow Life Fund's
            website or app if you do not accept all of the terms and conditions
            stated on this page.
          </p>

          <ol className="list-decimal  pl-[20px] text-gray-font sm:text-[16px] text-[15px] font-lato-medium-500 mt-[20px]">
            <li className="">Age Restriction</li>
            <p className=" text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
              The Site and App are not intended for individuals under the age of
              13. By using the Site or App, you represent that you are at least
              13 years old.
            </p>

            <li className="mt-[20px]"> Intellectual Property Rights</li>
            <p className=" text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
              Other than content you own, which you may have opted to include on
              this Website, under these Terms, Grow Life Fund and/or its
              licensors own all rights to the intellectual property and material
              contained in this Website, and all such rights are reserved.
            </p>
            <li className="mt-[20px]">Restrictions</li>
            <p className=" text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
              You are expressly restricted from the following:
            </p>

            <ul className="list-disc pl-[20px] text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400">
              <li className="">
                Publishing any Website material in any media without prior
                written consent.
              </li>
              <li className="">
                Selling, sublicensing, and/or otherwise commercializing any
                Website material.
              </li>
              <li className="">
                Publicly performing and/or showing any Website material.
              </li>
              <li className="">
                Using this Website in any way that is, or may be, damaging to
                this Website.
              </li>
              <li className="">
                Using this Website in any way that impacts user access to this
                Website.
              </li>
            </ul>
            <li className="mt-[20px]">User Content</li>
            <p className=" text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 ">
            Grow Life Fund does not allow users to create content on the Site or App. As such, the Website does not store or process any user-generated content except for the inputs necessary to use the services provided (e.g., SIP amount, years for calculation).
            </p>
            <li className="mt-[20px]">Disputes</li>
            <p className=" text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 ">
            Any disputes related to your use of the Website or App shall be governed by the laws of the state of Gujarat, India, without regard to its conflict of law provisions. Any dispute or claim arising out of or in connection with this Website shall be adjudicated exclusively in the courts of Surat, Gujarat, India.
            </p>
            <li className="mt-[20px]">Modifications to Terms</li>
            <p className=" text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 ">
            Grow Life Fund does not allow users to create content on the Site or App. As such, the Website does not store or process any user-generated content except for the inputs necessary to use the services provided (e.g., SIP amount, years for calculation).
            </p>
          </ol>
            <p className="text-gray-font sm:text-[16px] text-[14px] font-lato-regular-400 mt-[20px]">
            For any complaints or issues, please contact us at: contact(at)growlifefund(dot)com.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default TermsOfUse;
