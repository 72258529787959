import React, { useEffect, useState } from "react";
import CloseIcon from "../assets/images/close.svg";
import Input from "./Input";
import { Button } from "./Button";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddAmountSIP,
  setInvestmentAmounts,
  setIsOpen,
  setTempInvestmentAmounts,
} from "../redux/calculations/slice";
import {
  setAddAmountSIPLum,
  setInvestmentAmountsLum,
  setIsOpenLum,
  setTempInvestmentAmountsLum,
} from "../redux/calculations/lumpsumSlice";
import { FormatIndianNumber } from "../utiles/FormatIndianNumber";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import Cookies from "js-cookie";
import { parseCookie } from "../utiles/parseCookie";

export const FreeAmountModal = ({ onClose, activeTab, generateData }) => {
  const cookieAccept = parseCookie('grow-cookies');
  let totalYearCount = 0;
  const {
    noOfInvestementYear,
    isOpen,
    investmentAmounts,
    tempInvestmentAmounts,
  } = useSelector((state) => (activeTab === 0 ? state.sip : state.lum));
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);

  let newArray = tempInvestmentAmounts?.map((value) =>
  typeof value === "string" ? value.replace(/,/g, "") : value
  );

  newArray?.forEach((value) => {
    if ((typeof value !== "string" || value !== "0") && value !== 0 && value !== "") {// Check for non-zero and non-empty string values
      totalYearCount++;
    }
  });

  const totalFreeAmount = newArray?.reduce(
    (accumulator, currentValue) => accumulator + Number(currentValue),
    0
  );

  const [investmentYears, setInvestmentYears] = useState(
    Array.from({ length: noOfInvestementYear }, (_, index) => index + 1)
  );

  useEffect(() => {
    setInvestmentYears(
      Array.from({ length: noOfInvestementYear }, (_, index) => index + 1)
      );
      // if (!investmentAmounts.some((item) => item !== 0)) {
        dispatch(
          activeTab === 0
          ? setTempInvestmentAmounts([...investmentAmounts])
          : setTempInvestmentAmountsLum([...investmentAmounts])
          );
      dispatch(
        activeTab === 0
          ? setInvestmentAmounts(
              Array.from({ length: noOfInvestementYear }, () => 0)
            )
          : setInvestmentAmountsLum(
              Array.from({ length: noOfInvestementYear }, () => 0)
            )
      );

      dispatch(
        activeTab === 0
          ? setTempInvestmentAmounts(
              Array.from({ length: noOfInvestementYear }, () => 0)
            )
          : setTempInvestmentAmountsLum(
              Array.from({ length: noOfInvestementYear }, () => 0)
            )
      );
    // }
  }, [noOfInvestementYear]);

  const handleClose = () => {
    if (isOpen) {
      dispatch(setTempInvestmentAmounts([...investmentAmounts]));
      onClose();
    }
  };


  const handleInputChange = (index, value) => {
    const trimmedValue = value.trim();

    if (trimmedValue?.replace(/,/g, "").length > 8) {
      // If it exceeds, update errors array with the index
      setErrors([...errors, index]);
      return; // Prevent further processing
    } else {
      setErrors(errors?.filter((errorIndex) => errorIndex !== index));
    }

    const newTempInvestmentAmounts = [...tempInvestmentAmounts];
    newTempInvestmentAmounts[index] = value;
    dispatch(
      activeTab === 0
        ? setTempInvestmentAmounts(newTempInvestmentAmounts)
        : setTempInvestmentAmountsLum(newTempInvestmentAmounts)
    );
  };
  const handleBlur = (value, index) => {
    const trimmedValue = value && typeof value === 'string' ? value?.trim() : value;
    if (trimmedValue && trimmedValue?.replace(/,/g, "").length <= 8 && trimmedValue?.replace(/,/g, "") !== "0") {
      setErrors(errors.filter((errorIndex) => errorIndex !== index));
    }
  };
  const handleAddAmount = () => {
    dispatch(
      activeTab === 0
        ? setInvestmentAmounts(newArray)
        : setInvestmentAmountsLum(newArray)
    );
    dispatch(activeTab === 0 ? setIsOpen(false) : setIsOpenLum(false));
  };

  return (
    <>
      {isOpen ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center  w-full bg-black bg-opacity-50 p-[40px]">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="max-w-[1070px] sm:min-h-[300px] min-h-[200px] max-h-[95%] overflow-auto w-full  bg-white sm:p-[40px] m-[20px] p-[20px] rounded-lg shadow-lg">
              <div className="flex justify-between items-center">
                <div className="text-black text-left sm:text-[26px] text-[16px] sm:font-lato-semibold-600 font-lato-medium-500">
                  Add lumpsum amount in your Investment
                </div>
                <button className="Close-Icon" onClick={handleClose}>
                  <img
                    src={CloseIcon}
                    alt="close"
                    className="sm:w-auto w-[22px]"
                  />
                </button>
              </div>
              <div className="border-b border-gray-light sm:mt-[32px] mt-[16px]"></div>
              {noOfInvestementYear == 0 ? (
                <div className="text-black text-left sm:text-[22px] text-[16px] sm:font-lato-semibold-600 font-lato-medium-500 mt-[20px]">
                  First enter investment year to add lumpsum amount
                </div>
              ) : (
                <div className="sm:mt-[32px] mt-[16px] md:flex  block  gap-[40px]">
                  <div className="md:w-3/5 w-full  flex justify-between md:gap-[48px] sm:gap-[32px] gap-[16px]">
                    <div className="w-full">
                      <div className="text-black sm:text-[22px] text-[14px] sm:font-lato-medium-500 font-lato-regular-400 text-left ">
                        No. of Investment Year
                      </div>
                      {investmentYears.map((year, index) => (
                        <div className="mt-[16px]" key={index}>
                          <Input disabled value={year} />
                          {/* {errors?.includes(index) && (
                            <div className={"text-danger h-5 text-left pl-2"}>
                              {" "}
                            </div>
                          )} */}
                        </div>
                      ))}
                    </div>
                    <div className="w-full">
                      {" "}
                      <div className=" text-black sm:text-[22px] text-[14px] sm:font-lato-medium-500 font-lato-regular-400 text-left ">
                        Amount add for the Year
                      </div>
                      {tempInvestmentAmounts?.map((amount, index) => (
                        <div className="mt-[16px]" key={index}>
                          <Input
                            rightIcon="₹"
                            value={amount}
                            type="text"
                            onChange={
                              (e) => {
                                const inputVal = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                                let formattedValue =
                                  Number(inputVal).toLocaleString("en-IN");
                                handleInputChange(index, formattedValue);
                              }
                              // handleInputChange(index, e.target.value)}
                            }
                            // borderColor={
                            //   errors?.includes(index)
                            //     ? "border-danger"
                            //     : "border-primary"
                            // }
                            onBlur={() => handleBlur(amount, index)}
                          />
                          {/* {errors?.includes(index) && (
                            <p
                              className={
                                "text-danger text-[14px] text-left pl-2"
                              }
                            >
                              Maximum 8 digits allowed
                            </p>
                          )} */}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="md:w-2/5 w-full bg-primary-light rounded-[20px] md:mt-0 mt-[40px] sm:p-[24px] p-[20px] h-fit">
                    <div className="flex justify-between ">
                      <div className="sm:text-[20px] text-[16px]">
                        Total Year
                      </div>
                      <div className="sm:text-[20px] text-[16px]">
                        {totalYearCount ? totalYearCount : "0"} Yr
                      </div>
                    </div>
                    <div className="flex justify-between mt-[24px]">
                      <div className="sm:text-[20px] text-[16px]">
                        Total Amount
                      </div>
                      <div className="sm:text-[20px] text-[16px]">
                        ₹{" "}
                        {totalFreeAmount
                          ? FormatIndianNumber(totalFreeAmount)
                          : 0}
                      </div>
                    </div>
                    <div className="sm:mt-[32px] mt-[24px]">
                      <Button
                        className="w-full"
                        // disabled={errors?.length > 0 ? true : false}
                        onClick={() => {
                          cookieAccept && logEvent(analytics,`add_lumpsum_amount`)
                          handleAddAmount();
                          // addAmountSIP && generateData();
                          dispatch(
                            activeTab === 0
                              ? setAddAmountSIP(true)
                              : setAddAmountSIPLum(true)
                          );
                        }}
                      >
                        ADD
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
