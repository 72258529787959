import React from "react";

const Input = ({
  type,
  label,
  placeholder,
  value,
  onChange,
  leftIcon,
  rightIcon,
  disabled,
  className,
  borderColor,
  onBlur,
  tooltipContent,
  labelicon,
  tooltipClassName,
  ...rest
}) => {
  return (
    <div className="relative ">
      <label
        htmlFor={label}
        className=" sm:text-[16px]   text-[14px] text-left text-black font-lato-regular-400 flex items-center"
      >
        {label}
        <span className="group relative">
          <img src={labelicon} className="ml-[8px] " alt={labelicon?"lable-icon":""}/>
          <span className={` absolute hidden ${tooltipClassName} font-lato-regular-400 group-hover:flex justify-center  -top-[9px] -translate-y-full w-[250px]  m-auto px-3 py-3 bg-gray rounded-lg text-center text-white text-[12px] after:content-[''] after:absolute  after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-[transparent] after:border-b-[transparent] after:border-t-gray`}>
            {tooltipContent}
          </span>
        </span>
      </label>
      <div
        className={` sm:h-[38px] h-[32px] ${
          disabled ? "bg-primary-bg border text-gray border-primary-bg " : "  "
        } border ${
          borderColor || "border-primary"
        } border-opacity-50 flex items-center py-[7.5px] rounded-full ${
          label ? "sm:mt-[12px] mt-[10px]" : "mt-0"
        } px-[12px] ${className}`}
      >
        {leftIcon && (
          <div className=" ">
            <p
              className={`${
                borderColor || "border-primary"
              }  border-r   pr-[8px] sm:text-[16px] text-[14px] text-black font-lato-regular-400 `}
            >
              {leftIcon}
            </p>
          </div>
        )}
        <input
          labelicon={labelicon}
          type={type}
          autoComplete="off"
          id={label}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
          className="block w-full sm:pl-[12px] pl-[6px] focus:outline-none bg-[transparent] text-[16px]"
          {...rest}
        />
        {rightIcon && (
          <div className="">
            <p
              className={`border-l  ${
                borderColor || "border-primary"
              } pl-[8px] sm:text-[16px] text-[14px] text-black font-lato-regular-400`}
            >
              {rightIcon}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
