// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Cookies from "js-cookie";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let analytics = undefined; 
export const configureFirebase = () => {
  // const cAccept = localStorage.getItem("grow-cookies");
  const cAccept = Cookies.get("grow-cookies"); 
  const cookieAccept = cAccept === undefined ? null : JSON.parse(cAccept);
  // const cookieAccept = JSON.parse(cAccept);

  if (cookieAccept) {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };
  
    // Initialize Firebase
    const app = cookieAccept && initializeApp(firebaseConfig);
    analytics = cookieAccept && getAnalytics(app);
  }
}
export { analytics };
